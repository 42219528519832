import http from "../util/api_util";

const get = () => {
  return http.get(`/wallets/campaign`);
};
const getCampaign = (id:any) => {
  return http.get(`/wallets/campaign/${id}`);
};
const newRequest = (data:any) => {
    return http.post<any>(`/wallets/campaign`,data);
  };
const updateStatus = (id:any,data:any) => {
  return http.put<any>(`/wallets/campaign/${id}`,data);
};
const update = (id: any, data: any) => {
  return http.patch<any>(`/wallets/campaign/${id}`, data);
};
const deleteBudget = (cam_id: any, id: any) => {
  return http.delete<any>(`/wallets/campaign/budget/${cam_id}/${id}/`);
};
const deleteCampaign = (cam_id: any) => {
  return http.delete<any>(`/wallets/campaign/${cam_id}`);
};
const walletScratch = (data:string) => {
  return http.get<any>(`/wallets/ucase-transaction/'${data}'`);
};

const CouponScratch = (data:string) => {
  return http.get<any>(`/wallets/ucoupon-transaction/'${data}'`);
};

const Coupons = () => {
  return http.get<any>(`/wallets/coupons`);
};

const unScratchedCard = () => {
  return http.get<any>(`/wallets/unscratched-card`);
};

const ScratchedCard = (data:string) => {
  return http.get<any>(`/wallets/scratched-card/'${data}'`);
};

const CardWon = (data:string) => {
  return http.get<any>(`/wallets/card-won/'${data}'`);
};

const ScratchDetails = () => {
  return http.get<any>(`/wallets/scratch-details`);
};

const TotalCardWon = () => {
  return http.get<any>(`/wallets/total-card-won`);
};

const TotalUcashWon = () => {
  return http.get<any>(`/wallets/total-ucase`);
};

const TotalRedeemed = () => {
  return http.get<any>(`/wallets/redeemed-cash`);
};

const TotalScratched = () => {
  return http.get<any>(`/wallets/total-scratched`);
};

const CampaignConfigService = {
  get,
  getCampaign,
  newRequest,
  update,
  updateStatus,
  deleteBudget,
  deleteCampaign,
  walletScratch,
  unScratchedCard,
  ScratchedCard,
  CardWon,
  ScratchDetails,

  TotalCardWon,
  TotalUcashWon,
  TotalScratched,
  // TotalUnScratchCard,
  TotalRedeemed,
  Coupons,
  CouponScratch
};
export default CampaignConfigService;