import React, { ReactElement, FC, useState, useEffect} from "react";
import './pages.css';
import { useModal } from '../components/Modal/useModal'
import RadioButton from "../components/Radio/Radio";
import UserDataService from "../services/user_service";
import { useTranslation } from "react-i18next";
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import MuiAlert, { AlertProps,AlertColor } from '@mui/material/Alert';
const Privacy: FC<any> = (): ReactElement => {
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const defaultToast = { isOpen: false, message: ""}
  const [toast, setToast] = useState(defaultToast);
  const [color, setColor] = useState<AlertColor>("success");
  const toastAutoHideTime = 1000;
  const openToast = (options:any) => {
    setToast({
      isOpen: true,
      message: options.message ? options.message : ""
    })
    setTimeout(function(){
      setToast({ isOpen: false, message: ""})
    }, toastAutoHideTime)
  }
    const { t } = useTranslation();
    const { show: privacybtn,hide:hideprivacybtn, RenderModal: Privacypopup } = useModal()
    const [allPrivacy, changeAllPrivacy] = useState<any>({ 'connection_request': 'All Users', 'connection_request_options': [], 'connections': 'All Connections', 'contact_details': 'No one', 'personal_details': 'All Connections', 'photo': 'All Users' });
  const temp = [ 'connection_request', 'connections', 'contact_details', 'personal_details', 'photo' ]
  const subPrivacyArray = ['students', 'educators', 'professional', 'others'];
  const [subPrivacy, changeSubPrivacy] = useState<any>(subPrivacyArray);
  const [currentTab, changeCurrentTab] = useState("tab_0");
  const [tempStorage] = useState<any>({});
  const [currentTabTitle, changeCurrentTabTitle] = useState("Select who can send you");
  const [currentTabTitle1, changeCurrentTabTitle1] = useState(" connection request");
    const id = localStorage.getItem('user_token');
    const getUserPreference = (id: any) => {
        UserDataService.getUserPreference(id)
          .then((response: any) => {
            var result = response.data.data
            if(Object.keys(result.preference).length !== 0){
              changeAllPrivacy(result.preference)
              tempStorage['connection_request_options'] = result.preference.connection_request_options
              changeSubPrivacy(result.preference.connection_request_options)
            }
            if(!result.preference.connection_request){
              tempStorage['connection_request_options'] = subPrivacyArray
              changeSubPrivacy(subPrivacyArray);
            } else if(result.preference.connection_request === 'All Users'){
              tempStorage['connection_request_options'] = result.preference.connection_request_options
              changeSubPrivacy(result.preference.connection_request_options);
            }else{
              tempStorage['connection_request_options'] = []
              changeSubPrivacy([]);
            }
          })
          .catch((e: Error) => {
            
          });
      }
      useEffect(() => {
        if (id)
            getUserPreference(id);
        
      }, []);
    
  const radioChangeHandler = (event: any) => {
    
    let type = temp[Number(currentTab.replace("tab_", ""))]
    tempStorage[type] = event.target.value;
    let tempSubPrivacy = document.getElementsByName('sub_roles')
    if(type === 'connection_request' && event.target.value === 'All Users'){
      tempStorage['connection_request_options'] = subPrivacyArray;
      //changeSubPrivacy(subPrivacyArray)
      // changeAllPrivacy({...allPrivacy, connection_request:tempStorage[type]})
      tempSubPrivacy.forEach((item:any)=>{
        item.checked = true
      })
    }else if(type === 'connection_request' && event.target.value === 'No one'){
      tempStorage['connection_request_options'] = [];
      // changeAllPrivacy({...allPrivacy, connection_request:tempStorage[type]})
      tempSubPrivacy.forEach((item:any)=>{
        item.checked = false
      })
    }
  }

  const radioGroupChangeHandler = (event: any) => {
    
    // if (subPrivacy.includes(event.target.value)) {
    //   var index = subPrivacy.indexOf(event.target.value)
    //   if (index !== -1) {
    //     subPrivacy.splice(index, 1);
    //   }
    //   changeSubPrivacy(subPrivacy);
    // } else {
    //   subPrivacy.push(event.target.value);
    //   changeSubPrivacy(subPrivacy);
    // }
    // let type = temp[Number(currentTab.replace("tab_", ""))]
    // if(subPrivacy.length === 0)      
    //   changeAllPrivacy({...allPrivacy, connection_request:'No one'})
    // else
    //   changeAllPrivacy({...allPrivacy, connection_request:'All Users'})
    // if(!tempStorage['connection_request_options'])
    //   tempStorage['connection_request_options'] = subPrivacyArray
    if (tempStorage['connection_request_options'].includes(event.target.value)) {
      var index = tempStorage['connection_request_options'].indexOf(event.target.value)
      if (index !== -1) {
        tempStorage['connection_request_options'].splice(index, 1);
      }
    } else {
      tempStorage['connection_request_options'].push(event.target.value);
    }
    let tempSubPrivacy = document.getElementsByName('sub_roles')
    let tempCount = 0
    tempSubPrivacy.forEach((item:any)=>{
      if(item.checked)
        tempCount++
    })
    let tempPrivacy = document.getElementsByName('roles')
    tempPrivacy.forEach((item:any)=>{
      if(tempCount > 0){
        if(item.value === 'All Users'){
          item.checked = true
          tempStorage['connection_request'] = 'All Users';
        } else if(item.value === 'No one'){
          item.checked = false
        }
      } else if(tempCount === 0){
        if(item.value === 'No one'){
          item.checked = true
          tempStorage['connection_request'] = 'No one';
        } else if(item.value === 'All Users'){
          item.checked = false
        }
      }
    })
    
  }
  const privacyGroup = [
    [{
      'value': 'All Users', 'name': 'roles', 'label': 'All users', 'class': 'privacy_group', "type": "radio",
      'SubGroup': {
        "type": "checkbox",
        "options": [
          { 'value': 'students', 'name': 'sub_roles', 'label': 'Students', 'class': 'privacy_group' },
          { 'value': 'educators', 'name': 'sub_roles', 'label': 'Educators', 'class': 'privacy_group' },
          { 'value': 'professional', 'name': 'sub_roles', 'label': 'Industry Professional', 'class': 'privacy_group' },
          { 'value': 'others', 'name': 'sub_roles', 'label': 'Others', 'class': 'privacy_group' }
        ]
      }
    },
    { 'value': 'No one', 'name': 'roles', 'label': 'No one', 'class': 'privacy_group', "type": "radio" },
  ],
  [
    { 'value': 'All Connections', 'name': 'roles_1', 'label': 'All Connections', 'class': 'privacy_group', 'type': 'radio' },
    { 'value': 'All Users', 'name': 'roles_1', 'label': 'All Users', 'class': 'privacy_group', 'type': 'radio' },
    { 'value': 'No one', 'name': 'roles_1', 'label': 'No one', 'class': 'privacy_group', 'type': 'radio' },
  ]
  ];
    const updateSelection = (event: any) => {
      
      Object.keys(tempStorage).forEach(function(key) {
        allPrivacy[key] = tempStorage[key]
        changeAllPrivacy(allPrivacy);

        if(key === 'connection_request_options')
          changeSubPrivacy(tempStorage[key])
      });
        // if(allPrivacy.connection_request === 'All Users'){
        //   changeSubPrivacy(subPrivacy);
        //   allPrivacy.connection_request_options = subPrivacy
        // }else{
        //   delete allPrivacy.connection_request_options  
        //   changeSubPrivacy([]);
        // }
        // console.log(allPrivacy)
        UserDataService.updateUserPreference(id, {'type': 'privacy', 'privacy': allPrivacy})
        .then((response: any) => {
            hideprivacybtn()
            setColor("success")
            openToast({message:"Update User Preference"})
            
        })
        .catch((e: Error) => {

        });
    };
  const commonOnClick = (obj:any) => {
    
    changeCurrentTab(obj.tab);
    changeCurrentTabTitle(obj.tabTitle);
    changeCurrentTabTitle1(obj.tab1Title);
    privacybtn();
  }
  const backButton = () => {
    hideprivacybtn()
    getUserPreference(id)
  }

  var privacyCardDetail = [
    {privacyTitle: t('privacy_0'), priTitle: t('privacy_0_0'), privacyOnClick: commonOnClick, selected: allPrivacy.connection_request },
    {privacyTitle: t('privacy_1'), priTitle: t('privacy_1_1'), privacyOnClick:commonOnClick, selected: allPrivacy.connections },
    {privacyTitle: t('privacy_2'), priTitle: t('privacy_2_2'), privacyOnClick:commonOnClick, selected: allPrivacy.contact_details },
    {privacyTitle: t('privacy_3'), priTitle: t('privacy_3_3'), privacyOnClick:commonOnClick, selected: allPrivacy.personal_details },
    {privacyTitle: t('privacy_4'), priTitle: t('privacy_4_4'), privacyOnClick:commonOnClick, selected: allPrivacy.photo }
  ]

  const PrivacyCard = (props: any) => {
    return (
      <div className="accordian-main-strip">
        <div className="privacy-selected-items">
          <h3>{props.p_Title} <span className="privacy-selected-name">{props.p1_Title}</span></h3>
          <span>{props.p_selected}</span>
        </div>
        <p className="selection-popup-link-2" onClick={props.p_onlick} >
        <img src={process.env.PUBLIC_URL + 'assets/img/Edit_icon_g.svg'} alt="Public_icon" /><span className="iconDisplay">Edit</span></p>
        
      </div>
    );
  }

  

  return (
    <div className="ua-common-wrap">
      <h2 className="sect-head">{t('account_detail')}</h2>
      {
        privacyCardDetail.map((item: any, key: any) =>
          <PrivacyCard p_selected={item.selected} p_Title={t(item.privacyTitle)} p1_Title={t(item.priTitle)}  p_onlick={() => { item.privacyOnClick({"tab":'tab_'+key, "tabTitle":item.privacyTitle, "tab1Title":item.priTitle})} } />
        )
      }
      
      <Privacypopup>
        <div className="uc-email-otp-enter-popup">
          <div className="uc-add-email-popup">
            <div className="uc-modal-wrapper uc-modal-transition uc-modal-sm-wrapper">
              <div className="uc-modal-header pop-up-head privacy-head">
                <h2><span></span>{t('select_auidence')}</h2>
              </div>
              <div className="uc-modal-body">
                <div className="uc-msg-body pop-up-body privacy-modal">
                  <p>{currentTabTitle} <span className="privacy-selected-name">{currentTabTitle1}</span></p>
                  <div className="radio-option">
                    <div className='privacy-group'>
                      {
                            
                            privacyGroup[(Number(currentTab.replace("tab_", "")) === 0 ? 0 : 1)].map((item: any, index: any) => {
                              
                            return <RadioButton
                                key={index}
                                changed={radioChangeHandler}
                                id={index}
                                isSelected={allPrivacy[temp[Number(currentTab.replace("tab_", ""))]] === item.value}
                                selectedValue={allPrivacy[temp[Number(currentTab.replace("tab_", ""))]]}
                                defaultValue={'All Users'}
                                label={item.label}
                                value={item.value}
                                type={item.type}
                                name={item.name}
                                class={item.class}
                                SubGroup={item.SubGroup ? item.SubGroup : null}
                                groupChanged={item.SubGroup ? radioGroupChangeHandler : null}
                                subPrivacy={item.SubGroup ? subPrivacy : null}
                            />
                            })
                        
                      }
                    </div>
                  </div>
                </div>
                <div className="uc-tab-btn pop-up-bottom privacy-bottom">
                  <button type="button" className="btn-work ma-cancel" onClick={backButton}>{t('Back')}</button>
                  <button type="button" className="btn-work green-bg" onClick={updateSelection}>{t('Save')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Privacypopup>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={toast.isOpen} >
          <Alert  severity={color} sx={{ width: '100%' }}>
            {toast.message}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
};

export default Privacy;
