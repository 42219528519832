import { access } from 'fs';
import { Cookies } from 'react-cookie';


const cookies = new Cookies();

const setotp = (otp:any) =>{
  let current = new Date();
  let setdate = new Date();
  setdate.setTime(current.getTime() + 0.5 * 3600 * 1000);
  cookies.set('otp',otp,{path: '/', expires: setdate})
}

const getOtp = () =>{
  return cookies.get('otp')?cookies.get('otp'):'';
}

const removeOtp =() =>{
  cookies.remove('otp');
}
const set = (token:any) => {
  let current = new Date();
  let setdate = new Date();
    setdate.setDate(current.getDate()+1);
    // console.log(setdate)
    cookies.set('ulektz_token',token,{path: '/', expires: setdate})
    cookies.set('ulektz_log',true,{path: '/', expires: setdate})
    // cookies.set('myAccounts_userToken',token,{path: '/', expires: setdate})
};

const remove = () => {
  let current = new Date();
  let setdate = new Date();
    setdate.setDate(current.getDate()+1);
    cookies.remove('ulektz_token');
    cookies.remove('ulektz_id');
    cookies.remove('ulektz_inst');
    cookies.remove('ulektz_log');
    cookies.remove('ulektz_inst_url');
    // cookies.set('ulektz_token','',{path: '/', expires: setdate})
    // cookies.set('ulektz_id','',{path: '/', expires: setdate})
    // cookies.set('ulektz_inst','',{path: '/', expires: setdate})
    // cookies.set('ulektz_log','',{path: '/', expires: setdate})
    // cookies.set('PHPSESSID','',{path: '/', expires: setdate})
    // cookies.set('ulektz_inst_url','',{path: '/', expires: setdate})
    // cookies.set('myAccounts_userToken','',{path: '/', expires: setdate})
    // cookies.set('PHPSESSID','',{path: '/', expires: setdate})
    // cookies.set('ulektz_id','',{path: '/', expires: setdate})
    // cookies.remove('myAccounts_userToken');
};

// const get = () => {
//   let refresh_token = '"Authorization" : `Bearer '+ localStorage.getItem('refresh_token')+'`';
  
//   let access_token = cookies.get('myAccounts_userToken')
//   console.log(access_token)
//   return access_token?access_token:'';
// };
const get = () => {
  console.log(cookies.getAll());
  return cookies.get('ulektz_token')?cookies.get('ulektz_token'):'';
};

const header = () =>{
    let token = get()
    if (token) {
        return { Authorization: 'Bearer ' + token };
      } else {
        return { };
      }
};
const AuthService = {
  set,
  remove,
  get,
  header,
  setotp,
  getOtp,
  removeOtp,
};

export default AuthService;
