import React, { ReactElement, FC } from "react";


interface State {
    time: number;
    seconds: number;
    minuts: number;
    status:boolean;
}
interface ChildProps {
    retrunStatus: (data: any) => void;
  }
// const Timer:React.FC<Props> = ({time}) => {
const Timer: FC<ChildProps> = ({retrunStatus}): ReactElement => {
    const time = 50  
    const [state, setState] = React.useState<State>({
        time,
        seconds:time - Math.floor((time - 1) / 60) * 60-1,
        minuts:Math.floor((time - 1) / 60),
        status:true,
    });
    const sendDataToParentOnClick = (data:any) => {
        retrunStatus(data);
      };
    React.useEffect(() => {
        setTimeout(()=>{
            if(state.time === 0){
                sendDataToParentOnClick({status:state.status,time:state.seconds})
                return;
            }
            setState({
                time:state.time -1,
                minuts:Math.floor((state.time -1) / 60),
                seconds: state.time - Math.floor((state.time -1) / 60) * 60 - 1, 
                status:(state.time - Math.floor((state.time -1) / 60) * 60 - 1) === 0?false:true,
            });
            sendDataToParentOnClick({status:state.status,time:state.seconds})
        }, 1000);
    },[state.time]);
    return (
        <div className="timer-off btn-work outline-bg ">
            {
                `0${state.minuts}: 
                    ${state.seconds < 10 ? `0${state.seconds}`:state.seconds}`
            }
        </div>
    );
};

export default Timer;