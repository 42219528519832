import React, { useState } from "react";
import { Tabs, Tab, Typography, Box } from '@mui/material';
import Transaction from '../context/Wallet/Transactions';
// import UserPoints from '../context/Wallet/UserPoints';
import Scratch from '../context/Wallet/Scratch';
import Activities from '../context/Wallet/Activities';
import { useTranslation } from "react-i18next";
import Header from '../layouts/Header/Header';

// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch, { SwitchProps } from '@mui/material/Switch';


import { Link } from "react-router-dom";
import '../App.css';

//Tabs Design Starts
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    other?: object;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;
    const other: any = props.other ? props.other : {};
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${other.type ? other.type + '-' : ''}${index}`}
            aria-labelledby={`simple-tab-${other.type ? other.type + '-' : ''}${index}`}
            className={(other.class ? other.class : '') + ' customTab'}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number, type?: string) {
    return {
        id: `simple-tab-${type ? type + '-' : ''}${index}`,
        'aria-controls': `simple-tabpanel-${type ? type + '-' : ''}${index}`,
    };
}
//Tabs Design Ends
export default function BasicTabs() {
    const [title] = useState('Wallet Management');
    const [value, setValue] = React.useState(1);
    // const [setValueInner] = React.useState(0);
    const { t } = useTranslation();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 0) {
            // setValueInner(newValue);
        }
        setValue(newValue);
    };

    return (
        <>
        <Header/>
        <div className="uc-content-div" id="maindiv">
            
            <div className="uc-content-div-inside">
                <div className="breadCrumb-main"><Link to={'/'}>Dashboard</Link> / <Link to={''} className="item">{title}</Link></div>
                <div className="walletMain">
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="walletBoxOuter">
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="walletMainTabs">
                                <Tab label={t('activities')} {...a11yProps(0)} />
                                <Tab label={t('scratch_card')} {...a11yProps(1)} />
                                <Tab label={t('transaction')} {...a11yProps(2)} />
                                {/* <Tab label={t('user_points')} {...a11yProps(3)} /> */}
                            </Tabs>
                            <div className="uc-searchBar">
                                <input type="text" className="uc-formControl" id="searchbar" placeholder="Search" />
                                <input type="hidden" className="uc-formControl" id="searchtag" />
                            </div>
                        </Box>
                        <TabPanel value={value} index={0} other={{ class: 'tabHasNoInnerPanel' }}>
                            <Activities />
                        </TabPanel>
                        <TabPanel value={value} index={1} other={{ class: 'tabHasInnerPanel' }}>
                            <Scratch />
                        </TabPanel>
                        <TabPanel value={value} index={2} other={{ class: 'tabHasInnerPanel' }}>
                            <Transaction />
                        </TabPanel>
                        {/* <TabPanel value={value} index={3} other={{ class: 'tabHasInnerPanel' }}>
                            <UserPoints />
                        </TabPanel> */}
                    </Box>
                </div>
            </div>
        </div>
        </>
    );
}
