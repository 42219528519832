import React, { ReactElement, FC, useState, useEffect } from "react";
import "./header.css";
import tokenService from '../../services/token_service'
import { DialerSip } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth_service";
import UserService from "../../services/user_service";
import config from '../../config/config.json';
const Header: FC<any> = (): ReactElement => {

  const [state, setState] = useState("");
  const [currentUser, setCurrentUser] = useState<any>({});
  const toggleAccordion = () => {
    setState(state === "" ? "active" : "");
  }
  var id = localStorage.getItem('user_token')?localStorage.getItem('user_token'):'';
  var temp = ''
  if(id != null){
    temp = btoa(id)
  }
  const navigate = useNavigate();
  

  const start = () => {
    UserService.get()

      .then((response: any) => {
        let data1 = response.data
        if(data1.code === '3'){
          localStorage.clear();
          tokenService.set('')
          window.location.reload();
          return false
        }
        setCurrentUser(response.data);
        localStorage.setItem('user_fname', data1.first_name);
        localStorage.setItem('user_lname', data1.last_name);
        localStorage.setItem('user_email', data1.email);
        localStorage.setItem('user_token', data1.id);
        localStorage.setItem('user_image', data1.profile_image);
      })
      .catch((e: Error) => {
        console.log(e);
      });
    }
  const logout = () => {
    const data = {user_id:temp}
    AuthService.logout()
    .then((response: any) => {
      localStorage.clear();
      tokenService.set('')
      navigate('/')
      window.location.reload();
    })
    .catch((e: Error) => {
      console.log(e)
    });
    
  }
  useEffect(()=>{
    start()
  },[]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if(event.target.tagName === 'BUTTON' && event.target.parentNode.tagName === 'LI' && event.target.parentNode.classList.contains('ma-meoption')){
        return false;
      }
      if(event.target.tagName === 'IMG' && event.target.parentNode.tagName === 'BUTTON' && event.target.parentNode.parentNode.tagName === 'LI' && event.target.parentNode.parentNode.classList.contains('ma-meoption')){
        return false;
      }
      setState("");
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
  return (
    <header className="App-header">
      <div className="my-wrapper">
        <div className="ma-leftside">
          <a href={config.uLektz_Base_Path+'/dashboard'}><img src={process.env.PUBLIC_URL + 'assets/img/logo.png'} className="App-logo" alt="logo" /></a>
        </div>
        <div className="ma-rightside">
          <ul>
            {/* <li><a href="#">Apps<img src={process.env.PUBLIC_URL + 'assets/img/down-arrow.svg'} className="ua-downarrow" alt="DownArrow" /></a>
              <div className="ua-app-menu">

                    </div> 
            </li>
            <li><a href="#">Support</a></li>
            <li><a href="#"><img src={process.env.PUBLIC_URL + 'assets/img/Notification.svg'} className="" alt="Notification" /><span className='ua-count'>5</span></a></li>
            <li><a href="#"><img src={process.env.PUBLIC_URL + 'assets/img/Shopping_Cart.svg'} className="" alt="shopping cart" /></a></li> */}
            <li className={`ma-meoption ${state}`}>
              <button onClick={toggleAccordion} className={`${state}`}>
                <img src={config.uLektz_Profile_Image_Path+ localStorage.getItem('user_image')} className="" alt="shopping cart"  onError={({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src=process.env.PUBLIC_URL + 'assets/img/common-user.svg';
  }}/>Me</button>
              <div className="ua-me-option-list">
                <div className="ua-me-profile-img" >
                  <div className="ua-ackground-img">
                  <img src={config.uLektz_Profile_Image_Path+ localStorage.getItem('user_background_img')} className="" alt="shopping cart" 
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src=process.env.PUBLIC_URL + 'assets/img/common-background.png';
                  }}
                  />
                  </div>
                  <img src={config.uLektz_Profile_Image_Path+ localStorage.getItem('user_image')} className="" alt="shopping cart" 
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src=process.env.PUBLIC_URL + 'assets/img/common-user.svg';
                  }}
                  />
                  <h5>{localStorage.getItem('user_fname') + ' ' + localStorage.getItem('user_lname')}</h5>
                  <p>{localStorage.getItem('user_email')}</p>
                </div>
                <ul>
                  {/* <li><a href="#">Special manager</a></li> */}
                  {
                      (currentUser.id === 563482 || currentUser.id === 555317 || currentUser.id === 53009014) ?<li className='ua-signout'><Link to="/wallet">Wallet</Link></li>:<></>
                  }
                  <li className='ua-signout'><a onClick={logout}>Logout</a></li>
                  <div className="ua-line"></div>
                  <div className="uc-rigth-btn-div1">
                    <a href="https://www.ulektz.com/contact-us">Help</a>
                    <a href="https://www.ulektz.com/home/privacyPolicy">Privacy Policy</a>
                  </div>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
