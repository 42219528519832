import React, { useState } from "react";
import { Tabs, Tab, Typography, Box } from '@mui/material';
import AccountDeactive from '../context/Wallet/AccountDeactive';
import { useTranslation } from "react-i18next";
import Header from '../layouts/Header/Header';

// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch, { SwitchProps } from '@mui/material/Switch';


import { Link } from "react-router-dom";
import '../App.css';

//Tabs Design Starts
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    other?: object;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;
    const other: any = props.other ? props.other : {};
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${other.type ? other.type + '-' : ''}${index}`}
            aria-labelledby={`simple-tab-${other.type ? other.type + '-' : ''}${index}`}
            className={(other.class ? other.class : '') + ' customTab'}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number, type?: string) {
    return {
        id: `simple-tab-${type ? type + '-' : ''}${index}`,
        'aria-controls': `simple-tabpanel-${type ? type + '-' : ''}${index}`,
    };
}
//Tabs Design Ends
export default function BasicTabs() {
    const [title] = useState('Delete Account');
    const [value, setValue] = React.useState(0);
    
    // const [setValueInner] = React.useState(0);
    const { t } = useTranslation();
    
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 0) {
            // setValueInner(newValue);
        }
        setValue(newValue);
    };
    

    return (
        <>
        <Header/>
        <div className="uc-content-div" id="maindiv">
            
            <div className="uc-content-div-inside">
                <div className="breadCrumb-main"><Link to={'/'} className="ac-pre">Account Preferences</Link> / <Link to={''} className="item">{title}</Link></div>
                <div className="walletMain">
                    <Box sx={{ width: '100%' }}>
                    
                        <TabPanel value={value} index={0} other={{ class: 'tabHasNoInnerPanel' }}>
                            <AccountDeactive/>
                        </TabPanel>
                    </Box>
                </div>
            </div>
        </div>
        </>
    );
}
