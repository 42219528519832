import React, { useEffect, useState, useRef } from "react";
import { styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Paper, FormControlLabel, Switch } from '@mui/material';
import CustomStyleMenu from '../../components/StyleMenu/StyleMenu';
import config from '../../config/config.json';
import tokenService from '../../services/token_service';
import AuthService from "../../services/auth_service";
import UserDataService from "../../services/user_service";
import WalletConfigService from "../../services/wallet_config_service";
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslation } from "react-i18next";
import IconButton from '@mui/material/IconButton';
import { useModal } from "../../components/Modal/useModal";
import MuiAlert, { AlertProps,AlertColor } from '@mui/material/Alert'; 

//Table Design Starts
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

// function createData(
//     id: any,
//     status: any,
//     config: any,
//     code: any,
//     value: any,
// ) {
//     return { id, status, config, code, value };
// }
// Tabel Design Ends Here
// Switch Design Starts Here
const Android12Switch = styled(Switch)(() => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },

    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

// Switch Design Ends Here
// const style = {
//     position: 'absolute' as 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     p: 0,
//     borderRadius: 1
// };



const AccountDeactive = () => {

    // start Toast
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
      ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });
      const defaultToast = { isOpen: false, message: ""}
      const [toast, setToast] = useState(defaultToast);
      const [color] = useState<AlertColor>("success");
      const toastAutoHideTime = 1000;
      const openToast = (options:any) => {
        setToast({
          isOpen: true,
          message: options.message ? options.message : ""
        })
        setTimeout(function(){
          setToast({ isOpen: false, message: ""})
        }, toastAutoHideTime)
      }
      // End Toast

    const { t } = useTranslation();
    var md5 = require('md5');
    const [openmodal, setOpenModal] = React.useState(false);
    const navigate = useNavigate();
    const [editSection, changeEditSection] = React.useState({ id:'', config:'', code:'', value:'', status:'' });
    const { show: showDelete1, hide: hideDelete1, RenderModal: DeleteModel1 } = useModal();
    const { show: showOption, hide: hideOption, RenderModal: OptionModel } = useModal();
    const { show: showConfirmPassword, hide: hideConfirmPassword, RenderModal: ConfirmPassword } = useModal();
    const { show: showDeleted, hide: hideDeleted, RenderModal: DeletedModel } = useModal();
    const [errorDelete, setErrorDelete] = useState<any>({status:'vaild',code:0});
    const [showPassword, setShowPassword] = React.useState(false);
    const inputPhone = useRef<HTMLInputElement | null>(null)
    const [setOptions, changeOptions] = useState<any>("");
    const otherOptions = useRef<HTMLInputElement | null>(null)
    // const [value, setValue] = React.useState(0);
    // const [valueInner, setValueInner] = React.useState('');

    const handleChange = (newValue:any) => {
        if (newValue) {
            changeEditSection(newValue)
            // console.log(newValue)
            // if(newValue.activityName)
            //     setValueInner(newValue.activityName);
            // if(newValue.activityValue)
            //     setValue(newValue);
        }
        setOpenModal(true)
    };
    // const handleChangeInner = (newValueInner: number) => {
    //     setValueInner(newValueInner);
        
    // };

    //const handleOpenModal = () => setOpenModal(true);
    // const handleCloseModal = () => setOpenModal(false);
    const statusChange = (value:any,id:any) =>{
        WalletConfigService.updateStatus(id,value)
        .then((response: any) => {
            openToast({ message: response.data.message })
          changeEditSection(response.data)
        })
        .catch((e: Error) => {
          console.log(e);
        });
        
    }

    const commonOptions = [
        { 'icon': 'edit', 'label': 'Edit', 'click': handleChange }
    ];
    const options = [
        { 'value': 'I have a duplicate account', 'name': 'roles_1', 'label': 'I have a duplicate account', 'class': 'privacy-group', 'type': 'radio' },
        { 'value': 'I am getting too many emails', 'name': 'roles_1', 'label': 'I\'m getting too many emails', 'class': 'privacy-group', 'type': 'radio' },
        { 'value': 'I am not getting any value from my membership', 'name': 'roles_1', 'label': 'I\'m not getting any value from my membership', 'class': 'privacy-group', 'type': 'radio' },
        { 'value': 'I have privacy concern', 'name': 'roles_1', 'label': 'I have privacy concern', 'class': 'privacy-group', 'type': 'radio' },
        { 'value': 'I am receiving unwanted contacts', 'name': 'roles_1', 'label': 'I\'m receiving unwanted contacts', 'class': 'privacy-group', 'type': 'radio' },
        { 'value': 'Others', 'name': 'roles_1', 'label': 'Others', 'class': 'privacy-group others-option', 'type': 'radio' }
      ];
      
      const optionSelect = (event: any) => {
        changeOptions(event.target.value);
        setErrorDelete({status:'',code:0})
      }
    const Deletestep1 = () =>{
        hideDelete1();
        changeOptions("");
        showOption();
    }
    const handleOptionInputChange = () => {
        if(setOptions === 'Others'){
          if(otherOptions.current?.value !== ''){
            const divElement = document.getElementById('optionError'); // Get the div element by its id
            if (divElement) {
              divElement.style.display = 'none'; // Hide the div
            }
          }
        }
      }
    const DeleteLastStep = () =>{
        if(setOptions === ''){
            setErrorDelete({status:'Please select a reason',code:1})
            return false
        }
        if(setOptions === 'Others'){
            if(otherOptions.current?.value === ''){
            const divElement = document.getElementById('optionError'); // Get the div element by its id
            if (divElement) {
                divElement.style.display = 'block'; // Show the div
                divElement.innerText = 'Please enter the reason'; 
            }
            return false
            }
            changeOptions(otherOptions.current?.value);
        }
        hideOption();
        showConfirmPassword();
    }
    const handlePasswordInputChange = () => {
        if(inputPhone.current?.value !== ''){
          const divElement = document.getElementById('passwordError'); // Get the div element by its id
          if (divElement) {
            divElement.style.display = 'none'; // Hide the div
          }
        }
    }
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const ConfirmDelete = () =>{
        let password = inputPhone.current?.value;
        if(password === ''){
          const divElement = document.getElementById('passwordError'); // Get the div element by its id
            if (divElement) {
              divElement.style.display = 'block';
              divElement.innerText = 'Password is empty'; // Hide the div
            }
          return false
        }
        const data = {'reason': setOptions,'password':md5(password)};
        UserDataService.deleteAccount(data)
            .then((response: any) => {
              console.log(response.data);
              if(response.data.data.status !== 'failed'){
                hideConfirmPassword();
                showDeleted();
              }else{
                const divElement = document.getElementById('passwordError'); // Get the div element by its id
                if (divElement) {
                  divElement.style.display = 'block';
                  divElement.innerText = response.data.data.response; // Hide the div
                }
                return false
              }
            })
            .catch((e: Error) => {
            });
        
      }
      const Deleted = () =>{
        hideDeleted();
        logout();
      }
      const logout = () => {
        AuthService.logout()
        .then((response: any) => {
          console.log(response.data);
          localStorage.clear();
          tokenService.set('')
          navigate('/')
          window.location.reload();
        })
        .catch((e: Error) => {
          console.log(e)
        });
        
      }
    // const rows = [
    //     createData(
    //         1,
    //         <FormControlLabel className="activityLabel" control={<Android12Switch defaultChecked />} label="" />,
    //         'Points per like',
    //         100,
    //         <CustomStyleMenu items={commonOptions} />
    //     ),
    //     createData(2, <FormControlLabel className="activityLabel"
    //         control={<Android12Switch defaultChecked />}
    //         label=""
    //     />, 'Points per share', 50, <CustomStyleMenu items={commonOptions} />),
    //     createData(3, <FormControlLabel className="activityLabel"
    //         control={<Android12Switch defaultChecked />}
    //         label=""
    //     />, 'Profile likes', 30, <CustomStyleMenu items={commonOptions} />),
    //     createData(4, <FormControlLabel className="activityLabel"
    //         control={<Android12Switch />}
    //         label=""
    //     />, 'Profile connection', 300, <CustomStyleMenu items={commonOptions} />),
    //     createData(<Skeleton animation="wave" />, <FormControlLabel className="activityLabel"
    //         control={<Android12Switch />}
    //         label=""
    //     />, <Skeleton animation="wave" />, <Skeleton animation="wave" />, <CustomStyleMenu items={commonOptions} />),
    // ];

    return (
        <div>
            <div className="uc-accDeactive-div">
            <a href="javascript:void()" className="uca-close-mod" onClick={hideDelete1}></a>
                <div className="uc-modal-header pop-up-head">
                <div className="ua-me-profile-img" >
                    <img src={config.uLektz_Profile_Image_Path+ localStorage.getItem('user_image')} className="" alt="shopping cart"  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src=process.env.PUBLIC_URL + 'assets/img/common-user.svg';
                    }}/>
                    <h5>{localStorage.getItem('user_fname') + ' ' + localStorage.getItem('user_lname')}</h5>
                    <p>{localStorage.getItem('user_email')}</p>
                </div>
                </div>
                <div className="uc-modal-body">
                <div className="uc-msg-body pop-up-body">
                    <h3>{t('delete_head')}</h3>
                    <p>{t('delete_desc_1')}</p>
                </div>
                <div className="uc-tab-btn pop-up-bottom">
                    <button type="button" className="btn-work red-bg" onClick={Deletestep1}>{t('delete')}</button>             
                </div>
                </div>
            </div>
            <div className="delete-privacy" >
                  <a className="delete-btn-privacy" href={config.uLektz_Base_Path+'/deleteaccountPolicy'}>Delete account policy</a>
                </div>
            <OptionModel>
                <div className="uc-del-account-option-popup">
                <div className="uc-modal-wrapper uc-modal-transition uc-modal-md-wrapper">
                <a href="javascript:void()" className="uca-close-mod" onClick={hideOption}></a>
                    <div className="uc-modal-body">
                    <div className="uc-msg-body pop-up-body">
                        <h3><span>{localStorage.getItem('user_fname') + ' ' + localStorage.getItem('user_lname')+", we're Sorry to see you go"}</span></h3>
                        <p>Tell us the reason why you are closing your account</p>
                        {
                        options.map((item, index) =>{
                            return (
                            <div className={'md-radio '+item.class}>
                                <input name={item.name} id={item.name + '_' + index} value={item.value} type={item.type} checked={item.value === setOptions} onChange={optionSelect} />
                                <label htmlFor={item.name + '_' + index}>{item.label}</label>
                                {
                                setOptions === 'Others' && item.value === 'Others'?
                                <input type="text" placeholder="" ref={otherOptions} onChange={handleOptionInputChange} defaultValue={otherOptions.current?.value}/>
                                :''
                                }
                            </div>
                            )
                        }
                        )
                    }
                    {errorDelete.code === 1 && <div className="error">{errorDelete.status}</div>}
                    <div id="optionError" className="error" style={{ display: 'none' }}>{t('wrong_code')}</div>
                    </div>
                    <div className="uc-tab-btn pop-up-bottom">
                        <button type="button" className="btn-work save-btn" onClick={DeleteLastStep}>{t('Next')}</button>
                    </div>
                    </div>
                </div>
                </div>
            </OptionModel>
            <ConfirmPassword>
                <div className="uc-email-del-enter-popup">
                <div className="uc-add-email-popup">
                    <div className="uc-modal-wrapper uc-modal-transition uc-modal-md-wrapper">
                    <a href="javascript:void()" className="uca-close-mod" onClick={hideConfirmPassword}></a>
                    <div className="uc-modal-header pop-up-head">
                        <h2>Confirm Your Password</h2>
                    </div>
                    <div className="uc-modal-body">
                        <div className="uc-msg-body pop-up-body">
                        <p className="sub-text-body">Complete you delete request by entering the password associated with you account</p>
                        <div className="body-input">
                            <label>Password</label>
                                        <input type={showPassword?"text":"password"} id="addmobile" ref={inputPhone} placeholder="" onChange={handlePasswordInputChange} defaultValue={inputPhone.current?.value} autoFocus/>
                                        <span className="btn-icon"><InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment></span>
                                        
                        </div>
                        <div id="passwordError" className="error" style={{ display: 'none' }}></div>
                        {/* {errorPassword.code === 1 && <div className="error">{errorPassword.status}</div>} */}
                        </div>
                        
                        <div className="uc-tab-btn pop-up-bottom">
                        <button type="button" className="btn-work red-bg email-otp-enter-popup-link" onClick={ConfirmDelete}>{t('Delete')}</button>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </ConfirmPassword>
            <DeletedModel>
                <div className="uc-del-account-confirmed-popup">
                <div className="uc-modal-wrapper uc-modal-transition uc-modal-sm-wrapper">
                    <div className="uc-modal-body">
                    <div className="ua-me-profile-img">
                        <img src="assets/img/Remove_icon.svg" className="" alt="shopping cart"  onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src=process.env.PUBLIC_URL + 'assets/img/user.png';
                        }}/>
                    </div>
                    <div className="uc-msg-body pop-up-body">
                        <h3>{t('Your Account is Deleted')}</h3>
                        <p>{t('Sorry, to see you go')}</p>
                    </div>
                    <div className="uc-tab-btn pop-up-bottom">
                        <button type="button" className="btn-work save-btn" onClick={Deleted}>{t('Ok')}</button>
                    </div>
                    </div>
                </div>
                </div>
            </DeletedModel>
        </div>
    )
}


export default AccountDeactive
