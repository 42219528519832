import React, { ReactElement, FC } from "react";
// import { Tabs, Tab, Typography, Box } from '@mui/material';
import { styled, IconButton, Menu, MenuProps, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import FileCopyIcon from '@mui/icons-material/FileCopy';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const CustomStyleMenu: FC<any> = (props): ReactElement => {
    const items = props.items;
    const values = props.values ? props.values : null;
    
   
    const StyledMenu = styled((props: MenuProps) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))
    (({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 140,
            color:
                theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow: '0 2px 5px 0 rgb(0 0 0 / 10%)',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
            },
        },
    }));

    const icons:any = {
        'edit': <img src={process.env.PUBLIC_URL + 'assets/img/admin/edit.svg'} alt="" className="iconItem" />,
        'view': <img src={process.env.PUBLIC_URL + 'assets/img/admin/edit.svg'} alt="" className="iconItem" />,
        'delete': <img src={process.env.PUBLIC_URL + 'assets/img/admin/delete_icon.svg'} alt="" className="iconItem" />,
        'duplicate': <img src={process.env.PUBLIC_URL + 'assets/img/admin/duplicate_icon.svg'} alt="" className="iconItem" />,
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} >
                <MoreVertIcon />
            </IconButton>
            <StyledMenu id="demo-customized-menu" MenuListProps={{ 'aria-labelledby': 'demo-customized-button', }} anchorEl={anchorEl} open={open} onClose={handleClose} >
                {

                    
                    items && items.length > 0 && items.map((item: any, index: any) => 
                        
                         <MenuItem key={index} onClick={ item.click ? function(){
                            if(values)
                                item.click(values);
                            else
                                item.click();
                            handleClose();
                        } : function(){ handleClose(); } } disableRipple>
                             {icons[item.icon]}{item.label}
                         </MenuItem>
                    )
                }
            </StyledMenu>
        </>
    )
}

export default CustomStyleMenu;