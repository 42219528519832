import {authRequest, tokenRequest} from "../util/api_util";
import UserData from "../types/User";


const get = () => {
  return authRequest.get<UserData>(`/users`);
};
const update = (data: UserData) => {
  
  return authRequest.put<any>(`/users`,data);
};
const changepassword = (id: any, data: any) => {
  return authRequest.patch<any>(`/users/password/${id}`, data);
};
const getBlockedUser = (id: any) => {
    return authRequest.get<any>(`/users/get_blocked_users/${id}`);
};

const getUserPreference = (id: any) => {
    return authRequest.get<any>(`/users/get_user_preference/${id}`);
};
const updateUserPreference = (id: any, data:any) => {
    return authRequest.put<any>(`/users/get_user_preference/${id}`, data);
};
const sendOtp = (data:any) =>{
  return authRequest.post<any>(`/users/generate_otp`, data);
}
const verifyOtp = (data:any) =>{
  return authRequest.post<any>(`/users/verify_otp`, data);
}
const verifyMail = (data:any) =>{
  return authRequest.put<any>(`/users/verify_email`, data);
}
const deleteAccount = (data: any) => {
  return authRequest.put<any>(`users/deleteAccount`,data);
};
const UserService = {
  get,
  update,
  changepassword,
  getBlockedUser,
  getUserPreference,
  updateUserPreference,
  sendOtp,
  verifyOtp,
  verifyMail,
  deleteAccount
};
export default UserService;