import React, { useEffect } from 'react';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import Button from '@mui/material/Button';
import './scratch_style.css'
import CustomModal from '../../components/Modal/CustomModal';
import CustomDate from '../../components/Date/Date';
import CustomTime from '../../components/Date/Time';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import dayjs, { Dayjs } from 'dayjs';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Campaign from '../../components/Campaign/Campaign';
import { useTranslation } from "react-i18next";
import CampaignConfigService from '../../services/campaign_service';

//Table Design Starts
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// function createData(
//   sbutton: any,
//   fTime: any,
//   toTime: any,
//   budget: any,
//   maxAward: any
// ) {
//   return { sbutton, fTime, toTime, budget, maxAward };
// }
// Tabel Design Ends Here

//Tabs Design Starts
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  other?: object;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;
  const other: any = props.other ? props.other : {};

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${other.type ? other.type + '-' : ''}${index}`}
      aria-labelledby={`simple-tab-${other.type ? other.type + '-' : ''}${index}`}
      className={(other.class ? other.class : '') + ' customTab'}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number, type?: string) {
  return {
    id: `simple-tab-${type ? type + '-' : ''}${index}`,
    'aria-controls': `simple-tabpanel-${type ? type + '-' : ''}${index}`,
  };
}

const Scratch = () => {
  
  const { t } = useTranslation();
  const [openmodal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  //const handleCloseModal = () => setOpenModal(false);
  const [valueInner, setValueInner] = React.useState(0);
  const [campaignStartDate, setCampaignStartDate] = React.useState<any>(new Date());
  const [campaignEndDate, setCampaignEndDate] = React.useState<any>('');
  // const [time, setTime] = React.useState<Dayjs | null>(dayjs());

  const handleChangeInner = (event: React.SyntheticEvent, newValueInner: number) => {
    setValueInner(newValueInner);
  };
  const [eventCamp,setEventCamp] = React.useState<any>([])
  const [customCampaign,setCustomCampaign] = React.useState<any>({})
  const [campName,setCampName] = React.useState('')
  const [campaign,setCampaign] = React.useState<any>({})
  const [budget, setBudget] = React.useState(0);
  const [timing, setTiming] = React.useState<any>([{}])
  const changePassword = {
    from_time:dayjs(),
    to_time:dayjs(),
    budget:"",
    maximum:"",
  };
  const getValue = () =>{
    CampaignConfigService.get()
        .then((response: any) => {
          let data = response.data
          data.forEach((item:any) =>{
            if(item.status === '1'){
              setCampaign(item)
            }else{
              setCusCamp(item)
            }
        });
          // setCampaign(response.data[0]);
        })
        .catch((e: Error) => {
          console.log(e);
        });

}

const setCusCamp =(camp:any)=>{
  let data = eventCamp;
  data.push(camp)
  data = [...data]
  data = data.filter(function(x:any) { return x !== null });
  
  setEventCamp(data)
}
useEffect(() => {
    getValue()
}, []);


const changeCampName = (value:any)=>{
  setCampName(value)
}
const changeCampbudget = (value:any)=>{
  setBudget(value)
}
const handleAddRow = () => {
  const a = new Date(timing[timing.length - 1].to_time)
  
  if(a.getHours() !== 23 && a.getMinutes() !== 59){

  if(((timing[timing.length - 1].to_time) != "") && (typeof (timing[timing.length - 1].to_time) != "undefined") ){
    let data = timing;
    let time = {
      from_time:dayjs((timing[timing.length - 1].to_time)+60000).valueOf(),
      to_time:"",
      budget:"",
      minimum:"",
      maximum:"",
    }
    data.push(time)
    setTiming([...data]);
  }
}
};

const handleRemoveRow = (item:any) => {
  if(item != 0){
    var data = timing;
    data.splice(item, 1);
    setTiming([...data]);
  }else{
    timing[0].from_time= "";
    timing[0].to_time= "";
    timing[0].budget= "";
    timing[0].minimum= "";
    timing[0].maximum= "";
    
  }
}
const changeTiming = (index:any, params:any = {}) => {
  
  if(params.type === 'fromTime')
      timing[params.index].from_time = dayjs(index).valueOf();
  if(params.type === 'toTime')
      timing[params.index].to_time = dayjs(index).valueOf();
  if(params.type === 'budget')
      timing[params.index].budget = index;
  if(params.type === 'minimum')
      timing[params.index].minimum = index;
  if(params.type === 'maximum')
      timing[params.index].maximum = index;
  setTiming([...timing])
  var temp = 0
  for (let i = 0; i < timing.length; i++) {
    temp += parseInt(timing[i].budget);
  }
  setBudget(temp)
  //setFromTime(index)
}
const resetCampaign = () => {
  setCampName("")
  setBudget(0)
  setCampaignStartDate(new Date())
  setCampaignEndDate('')
  setTiming([{}])
  setOpenModal(false)
}
const displayRow = (row:any,index:any) =>{
  return <StyledTableRow key={index}>
                                       <StyledTableCell>
                                        <div className='customToggle'>
                                            <IconButton aria-label="add item">
                                                <RemoveIcon onClick={(e)=>handleRemoveRow(index)} />
                                            </IconButton>
                                        </div>
                                       </StyledTableCell>
                                       <StyledTableCell>
                                           <div className='customTimer'>
                                               
                                               <CustomTime value={row.from_time} onChange={changeTiming} returnParams={{index:index,type:'fromTime'}} options={{ OpenPickerIcon: ArrowDropDownIcon }} />
                                               
                                           </div>
                                       </StyledTableCell>

                                       <StyledTableCell>
                                           <div className='customTimer'>
                                               <CustomTime value={row.to_time} onChange={changeTiming}  returnParams={{index:index,type:'toTime'}} options={{ OpenPickerIcon: ArrowDropDownIcon, minTime:row.from_time?row.from_time:''  }} />
                                           </div>
                                       </StyledTableCell>

                                       <StyledTableCell><input type="text" defaultValue={row.budget}  className='uc-formControl' onChange={(e)=>changeTiming(e.target.value,{index:index,type:'budget'})}/></StyledTableCell>

                                       <StyledTableCell><input type="text" defaultValue={row.minimum}   className='uc-formControl' onChange={(e)=>changeTiming(e.target.value,{index:index,type:'minimum'})}/></StyledTableCell>

                                       <StyledTableCell><input type="text" defaultValue={row.maximum}   className='uc-formControl' onChange={(e)=>changeTiming(e.target.value,{index:index,type:'maximum'})}/></StyledTableCell>
                                   </StyledTableRow>
}
const saveCampaign = () =>{
  
  customCampaign.name = campName;
  customCampaign.budget_per_day = budget;
  customCampaign.active = '1';
  customCampaign.status = '0';
  customCampaign.from_date = dayjs(campaignStartDate).valueOf();
  customCampaign.to_date = dayjs(campaignEndDate).valueOf();
  customCampaign.camp_budget = [...timing]
  var temp = 0
  for (let i = 0; i < timing.length; i++) {
    temp += parseInt(timing[i].budget);
      if(timing[i].from_time === "" && timing[i].minimum === "" && timing[i].maximum === "" && timing[i].to_time === "" && timing[i].budget === ""){
          alert("Data Empty Field")
          return false;
      }

      if(timing[i].maximum < timing[i].minimum){
          alert("Minimum Awarded greater then Maximum")
          return false;
      }

      if(timing[i].budget < timing[i].minimum){
          alert("Minimum Awarded greater then Budget")
          return false;
      }

      if(timing[i].budget < timing[i].maximum){
          alert("Maximum Awarded greater then Budget")
          return false;
      }
  }
  
  
  if(temp != customCampaign.budget_per_day){
      alert("budget Calculation Mismatched ")
      return false;
  }
  
  // console.log("Checking :",((timing[timing.length-1].to_time)).diff(timing[0].from_time, 'hours',true))
  // console.log(dayjs(timing[0].from_time),' || ',dayjs(timing[timing.length-1].to_time))
  // console.log(dayjs().isBetween(timing[0].from_time, timing[timing.length-1].to_time, 'hours', '[]'))
  // console.log("budget_per_day :", customCampaign.budget_per_day)
      CampaignConfigService.newRequest(customCampaign)
          .then((response: any) => {
            setCustomCampaign({})
          })
          .catch((e: Error) => {
          console.log(e);
      });
 

}
  // const rows = [
  //   createData(
  //     <div className='customToggle'>
  //       <IconButton aria-label="add item">
  //         <RemoveIcon />
  //       </IconButton>
  //     </div>,
  //     <div className='customTimer'><CustomTime value={time} onChange={setTime} options={{ OpenPickerIcon: ArrowDropDownIcon }} /></div>,
  //     <div className='customTimer'><CustomTime value={time} onChange={setTime} options={{ OpenPickerIcon: ArrowDropDownIcon }} /></div>,
  //     <input type="text" className='uc-formControl' />,
  //     <input type="text" className='uc-formControl' />
  //   ),
  //   createData(
  //     <div className='customToggle'>
  //       <IconButton aria-label="add item">
  //         <RemoveIcon />
  //       </IconButton>
  //     </div>,
  //     <div className='customTimer'><CustomTime value={time} onChange={setTime} options={{ OpenPickerIcon: ArrowDropDownIcon }} /></div>,
  //     <div className='customTimer'><CustomTime value={time} onChange={setTime} options={{ OpenPickerIcon: ArrowDropDownIcon }} /></div>,
  //     <input type="text" className='uc-formControl' />,
  //     <input type="text" className='uc-formControl' />
  //   ),
  //   createData(
  //     <div className='customToggle'>
  //       <IconButton className='addButton' aria-label="remove item">
  //         <AddIcon />
  //       </IconButton>
  //     </div>,
  //     <div className='customTimer'><CustomTime value={time} onChange={setTime} options={{ OpenPickerIcon: ArrowDropDownIcon }} /></div>,
  //     <div className='customTimer'><CustomTime value={time} onChange={setTime} options={{ OpenPickerIcon: ArrowDropDownIcon }} /></div>,
  //     <input type="text" className='uc-formControl' />,
  //     <input type="text" className='uc-formControl' />
  //   ),

  // ];
 
  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={valueInner} onChange={handleChangeInner} aria-label="basic tabs example" className="walletSubTabs">
          <Tab label={t('default_setting')} {...a11yProps(0, 'inner')} />
          <Tab label={t('campaign_setting')} {...a11yProps(1, 'inner')} />
          <Tab label={t('coupon')} {...a11yProps(2, 'inner')} disabled />
        </Tabs>
      </Box>
      <TabPanel value={valueInner} index={0} other={{ type: 'inner', class: 'tabInnerPanel' }}>
        {campaign.camp_budget?
        <Campaign default={true} open={true} campaign={campaign} edit={true} />
        :<></>
        }
      </TabPanel>
      <TabPanel value={valueInner} index={1} other={{ type: 'inner', class: 'tabInnerPanel' }}>
        <Button variant="contained" color="primary" onClick={handleOpenModal}>
          + {t("create_campaign")}
        </Button>
        <div className="campaign-setting-main">
          {/* <div className="campaign-setting-block">
            <div className="campaign-setting-header">
              <div className="campaign-header-lt">
                <FormControlLabel className="activityLabel" control={<Android12Switch defaultChecked />} label="" />
                <div className="campaign-name">
                  <h4>Weekend Campaign <a href="javascript:void(0)"><img src={process.env.PUBLIC_URL + 'assets/img/admin/edit.svg'} alt="" /></a> </h4>
                </div>
              </div>
              <div className="campaign-header-rt">
                <span className='campaign-badge'>On Live</span>
                <div className="campaign-setting-date">
                  <p>Date:</p>
                  <div className="date-picker-wrapper">
                    <div className="date-picker">
                      <CustomDate value={campaignStartDate} onChange={setCampaignStartDate} options={{ minDate: '06-08-2022', maxDate: new Date().toString(), OpenPickerIcon: ArrowDropDownIcon }} />
                    </div>
                    <div className="date-picker">
                      <CustomDate value={campaignEndDate} onChange={setCampaignEndDate} options={{ minDate: '06-08-2022', maxDate: new Date().toString(), OpenPickerIcon: ArrowDropDownIcon }} />
                    </div>
                  </div>
                </div>
                <div className="campaign-budget">
                  <h4>uCash Budget Per Day <span className='budget'><CurrencyRupeeIcon />5000</span><a href="javascript:void(0)"><img src={process.env.PUBLIC_URL + 'assets/img/admin/edit.svg'} alt="" /></a> </h4>
                </div>
                <CustomStyleMenu items={commonOptions} />
              </div>
            </div>
            <div className="campaign-setting-body">
              <TableContainer component={Paper} className="sa-create-camp">
                <Table sx={{ maxWidth: 677 }} aria-label="customized table" className="activityTabel">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>From Time</StyledTableCell>
                      <StyledTableCell>To Time</StyledTableCell>
                      <StyledTableCell>Budget</StyledTableCell>
                      <StyledTableCell>Maximum Award</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.sbutton}>
                        <StyledTableCell>{row.sbutton}</StyledTableCell>
                        <StyledTableCell>{row.fTime}</StyledTableCell>
                        <StyledTableCell>{row.toTime}</StyledTableCell>
                        <StyledTableCell>{row.budget}</StyledTableCell>
                        <StyledTableCell>{row.maxAward}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div> */}
          {/* <Campaign default={false} open={true} campaign={campaign} edit={false}/>*/
         [...eventCamp].map((row:any,index:any) => (
          
          <Campaign default={false} open={index===0} campaign={row} edit={false}/>
          
        )) 
        }
        </div>
      </TabPanel>
      <TabPanel value={valueInner} index={2} other={{ type: 'inner', class: 'tabInnerPanel' }}>
        Item Inner Three
      </TabPanel>
      <CustomModal width={737} modalID="modal_two" isOpen={openmodal} changeOpen={setOpenModal} title={t('Create Campaign')} saveButtonText={'Create'} saveButtonFunction={() => { saveCampaign(); setOpenModal(false) }} cancelButtonText={'Cancel'} cancelButtonFunction={() => { resetCampaign();  }}>
        <div className="create-modal-main">
          <div className="form-group-block">
            <div className="sa-form-group-row">
              <div className="sa-form-group sa-col-8">
                <label htmlFor="">{t("campaign_name")}</label>
                <input type="text" placeholder='Campaign Name' defaultValue={campName} onChange={(e)=>changeCampName(e.target.value)} className='uc-formControl' />
              </div>
              <div className="sa-form-group sa-col-4">
                <label htmlFor="">{t("tot_ucash_budget")}</label>
                <input type="text" placeholder='Total uCash Budget' value={budget} disabled  className='uc-formControl' />
              </div>
            </div>
            <div className="sa-date-block">
              <div className="sa-form-group-row">
                <div className="sa-form-group date-picker">
                  <label htmlFor="">{t("start_date")}</label>
                  <CustomDate value={campaignStartDate} onChange={setCampaignStartDate} options={{ minDate: new Date(),maxDate:campaignEndDate?campaignEndDate:'', OpenPickerIcon: ArrowDropDownIcon }} />
                </div>
                <div className="sa-form-group date-picker">
                  <label htmlFor="">{t("end_date")}</label>
                  <CustomDate value={campaignEndDate} onChange={setCampaignEndDate} options={{ minDate: campaignStartDate,  OpenPickerIcon: ArrowDropDownIcon }} />
                </div>
              </div>
            </div>
            {/* <CustomTime value={time} onChange={setTime} options={{ OpenPickerIcon: ArrowDropDownIcon }} /> */}
            {/* <CustomToggleButton /> */}
            <TableContainer component={Paper} className="sa-create-camp">
              <Table sx={{ minWidth: 677 }} aria-label="customized table" className="activityTabel">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <div className='customToggle'>
                          <IconButton className='addButton' aria-label="remove item">
                              <AddIcon  onClick={handleAddRow}/>
                          </IconButton>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>{t("from_time")}</StyledTableCell>
                    <StyledTableCell>{t("to_time")}</StyledTableCell>
                    <StyledTableCell>{t("budget")}</StyledTableCell>
                    <StyledTableCell>{t("min_award")}</StyledTableCell>
                    <StyledTableCell>{t("max_award")}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...timing].map((row:any,index:any) => (
                    displayRow(row,index)
                  ))} 
                </TableBody>
              </Table>
            </TableContainer> 
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

export default Scratch
