import React, { ReactElement, FC } from "react";

// import dayjs, { Dayjs } from 'dayjs';
// import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from '@mui/material';
import './datepicker.css';



const CustomDate: FC<any> = (props): ReactElement => {

    const value = props.value?props.value:'';
    const options = props.options;
    const date = props.value?new Date(Number(props.value)):'';
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Custom input"
                    value={date}
                    components={{
                      OpenPickerIcon: options.OpenPickerIcon ? options.OpenPickerIcon : ''
                    }}
                    
                    disabled={options.disabled}
                    minDate={options.minDate ? new Date(Number(options.minDate)) : ''}
                    maxDate={options.maxDate ? new Date(Number(options.maxDate)) : ''}
                    onChange={(newValue) => {
                        props.onChange(newValue);
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <input ref={inputRef} {...inputProps} />
                        {InputProps?.endAdornment}
                      </Box>
                    )}
                  />
                </LocalizationProvider>
        </>
    )
}

export default CustomDate;