import {authRequest, tokenRequest} from "../util/api_util";
import User from "../types/User";
import config from "../config/config.json"
const login = (username: any, password: any) => {

    let param = {'username': username, 'password': password}
    return tokenRequest.post<User>(`/users/login`, param);
};
const googlelogin = (data:any) => {
    return tokenRequest.post<any>(`https://development.ulektz.com/start/googleLogin`,data,{headers:{ 'Content-Type': 'application/json','Access-Control-Allow-Origin' : '*','accept': 'application/json'}});
};
const logout = () => {
    return authRequest.get<any>(`/users/logout`);
};
const AuthService = {
    login,
    googlelogin,
    logout
};

export default AuthService;
