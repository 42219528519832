import React, { ReactElement, FC } from "react";

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Box } from '@mui/material';
import './timepicker.css'



const CustomTime: FC<any> = (props): ReactElement => {
    
    // const value = props.value?props.value:'';
    const options = props.options;
    const time = props.value?new Date(Number(props.value)):'';
    const minTime = new Date(Number(options.minTime));
    const maxTime = new Date(Number(options.maxTime));
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                    ampm={false}
                    className="customTimer"
                    label="Custom input"
                    value={time}
                    inputFormat="HH:mm:ss"
                    
                    // is24Hour={true}
                    components={{
                        OpenPickerIcon: options.OpenPickerIcon ? options.OpenPickerIcon : ''
                    }}
                    
                    // disabled={options.disabled}
                    minTime={options.minTime ? dayjs(dayjs(minTime).format('YYYY-MM-DDTHH:mm')):''}
                    maxTime={options.maxTime ? dayjs(dayjs(maxTime).format('YYYY-MM-DDTHH:mm')):''}
                    //maxTime={dayjs('2023-12-01T18:45')}
                    // minTime={options.minTime ? options.minTime : ''}
                    // maxTime={options.maxTime ? options.maxTime : ''}
                    onChange={(newValue) => {
                        if(props.returnParams){
                            props.onChange(newValue, props.returnParams);
                        } else {
                            props.onChange(newValue);
                        }
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <input ref={inputRef} {...inputProps} disabled={true} placeholder={'hh:mm'}/>
                            {InputProps?.endAdornment}
                        </Box>
                    )}
                />
            </LocalizationProvider>
        </>
    )
}

export default CustomTime;