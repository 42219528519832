import React, { useState,useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import CustomDate from '../Date/Date';
import CustomTime from '../Date/Time';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import dayjs, { Dayjs } from 'dayjs';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CustomStyleMenu from '../StyleMenu/StyleMenu';
import { styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Paper, Switch, FormControlLabel } from '@mui/material';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import CampaignConfigService from '../../services/campaign_service';
import { getValue } from '@testing-library/user-event/dist/utils';
import { count } from 'console';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import MuiAlert, { AlertProps,AlertColor } from '@mui/material/Alert'; 
import { config } from 'dotenv';



//Table Design Starts
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const today = new Date()
function createData(
    sbutton: any,
    fTime: any,
    toTime: any,
    budget: any,
    maxAward: any
) {
    return { 
        sbutton, 
        fTime, 
        toTime, 
        budget, 
        maxAward };
}
// Tabel Design Ends Here

//Tabs Design Starts
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    other?: object;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;
    const other: any = props.other ? props.other : {};

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${other.type ? other.type + '-' : ''}${index}`}
            aria-labelledby={`simple-tab-${other.type ? other.type + '-' : ''}${index}`}
            className={(other.class ? other.class : '') + ' ' + 'customTab'}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number, type?: string) {
    return {
        id: `simple-tab-${type ? type + '-' : ''}${index}`,
        'aria-controls': `simple-tabpanel-${type ? type + '-' : ''}${index}`,
    };
}

// Switch Design Starts Here
const Android12Switch = styled(Switch)(() => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },

    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

// Switch Design Ends Here

const Campaign = (props: any) => {


    // start Toast
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
      ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });
      const defaultToast = { isOpen: false, message: ""}
      const [toast, setToast] = useState(defaultToast);
      const [color, setColor] = useState<AlertColor>("success");
      const toastAutoHideTime = 1000;
      const openToast = (options:any) => {
        setToast({
          isOpen: true,
          message: options.message ? options.message : ""
        })
        setTimeout(function(){
          setToast({ isOpen: false, message: ""})
        }, toastAutoHideTime)
      }
      // End Toast

    const { t, i18n } = useTranslation();
    const defaultCampaign = props.default;
    const [campaign,setCampaign] = React.useState(props.campaign)
    const [open, changeOpen] = React.useState(props.open);
    
    const [cam_status,setCamStatus] = React.useState(props.campaign.active)
    const [campaignStartDate, setCampaignStartDate] = React.useState<any>(props.campaign.from_date);
    const [editDefault, changeEditDefault] = React.useState(props.edit);
    const [campaignEndDate, setCampaignEndDate] = React.useState<any>(props.campaign.to_date);
    const [campChange,setCampChange] = React.useState(dayjs().isBefore(campaignEndDate, 'hours'))
    const [editCampaign, changeEditCampaign] = React.useState(true);
    const [budget, setBudget] = React.useState(props.campaign.budget_per_day);

    const [campaignName, updateCampaignName] = React.useState(campaign.name);
    const changePassword = {
        from_time:dayjs(),
        to_time:dayjs(),
        budget: 0,
        minimum:0,
        maximum:0,
        campaign_id:0
      };
    const [timing, setTiming] = React.useState<any>(props.campaign.camp_budget)
    const statusChange = (value:boolean)=>{
        if(value)
            setCamStatus('1')
        else
            setCamStatus('0')
    }
    const changeStartDate = (params:any = {}) =>{
        setCampaignStartDate(dayjs(params).valueOf())
    }
    const changeEndDate = (params:any = {}) =>{
        setCampaignEndDate(dayjs(params).valueOf())
    }
    const changeTiming = (index:any, params:any = {}) => {
        //console.log(timing[params.index+1].from_time)
        if(params.type == 'fromTime'){
            if (typeof (timing[params.index-1]) !== "undefined") {
                if(timing[params.index-1].to_time < dayjs(index).valueOf()){
                    timing[params.index].from_time = dayjs(index).valueOf();
                }else{
                    setColor("warning")
                    openToast({ message: "Not Vaild" })
                }
            }else{
                if(timing[params.index].to_time > dayjs(index).valueOf()){
                    timing[params.index].from_time = dayjs(index).valueOf();
                }else{
                    setColor("warning")
                    openToast({ message: "Not Vaild" })
                }
            }
            
        }
        if(params.type == 'toTime'){
            if (typeof (timing[params.index+1]) !== "undefined") {
                if(timing[params.index+1].from_time > dayjs(index).valueOf()){
                    timing[params.index].to_time = dayjs(index).valueOf();
                }else{
                    setColor("warning")
                    openToast({ message: "Not Vaild" })
                }
            }else{
                if(timing[params.index].from_time < dayjs(index).valueOf()){
                    timing[params.index].to_time = dayjs(index).valueOf();
                }else{
                    setColor("warning")
                    openToast({ message: "Not Vaild" })
                }
            }
        }
        if(params.type == 'budget')
            timing[params.index].budget = parseInt(index);
        if(params.type == 'minimum')
            timing[params.index].minimum = parseInt(index);
        if(params.type == 'maximum')
            timing[params.index].maximum = parseInt(index);
        timing[params.index].campaign_id = campaign.id;
        setTiming([...timing])
        var temp = 0
        for (let i = 0; i < timing.length; i++) {
          temp += parseInt(timing[i].budget);
        }
        setBudget(temp)
    }

    const changeCampName = (value:any)=>{
        updateCampaignName(value)
    }


    const customOpenChange = () => {
        changeOpen(!open)
    }
    const customDelete = (id:any) => {
        CampaignConfigService.deleteCampaign(id.id.id)
                .then((response: any) => {
                    openToast({ message: "Campaign Deleted" })
                    window.location.reload();
                })
                .catch((e: Error) => {
                console.log(e);
            });
    }
    const customDuplicate = (id:any) => {
        setCampaign(id.id)
        campaign.id = '';
        campaign.name = 'Dublicate '+campaign.name;
        const budget = id.id.camp_budget;
        for (let i = 0; i < budget.length; i++) {
            delete budget[i].id;
            delete budget[i].campaign_id;
        }
        campaign.from_date = new Date()
        campaign.to_date = new Date()
        campaign.camp_budget = budget
        CampaignConfigService.newRequest(campaign)
            .then((response: any) => {
                openToast({ message: "Create Duplicate Campaign" })
                window.location.reload();
            })
            .catch((e: Error) => {
                console.log(e);
        });
        // CampaignConfigService.deleteCampaign(id.id)
        //         .then((response: any) => {
        //             console.log(response)
        //             openToast({ message: "Deleted Campaign" })
        //         })
        //         .catch((e: Error) => {
        //         console.log(e);
        //     });
    }

    const saveCustomCampaign = () =>{
        
        
        campaign.name = campaignName;
        campaign.budget_per_day = budget;
        campaign.active = cam_status;
        campaign.status = '0';
        campaign.from_date = campaignStartDate
        campaign.to_date = campaignEndDate
        campaign.camp_budget = [...timing]
        var temp = 0
        for (let i = 0; i < timing.length; i++) {
            temp += parseInt(timing[i].budget);
            if(timing[i].from_time === "" && timing[i].minimum === "" && timing[i].maximum === "" && timing[i].to_time === "" && timing[i].budget === ""){
                alert("Data Empty Field")
                return false;
            }

            if(timing[i].maximum < timing[i].minimum){
                alert("Minimum Awarded greater then Maximum")
                return false;
            }

            if(timing[i].budget < timing[i].minimum){
                alert("Minimum Awarded greater then Budget")
                return false;
            }

            if(timing[i].budget < timing[i].maximum){
                alert("Maximum Awarded greater then Budget")
                return false;
            }
        }
        
        // return false;
        CampaignConfigService.update(campaign.id,campaign)
                .then((response: any) => {
                    openToast({ message: "Campaign Successfully Updated" })
                })
                .catch((e: Error) => {
                console.log(e);
            });
        // }else{
        //     CampaignConfigService.newRequest(campaign)
        //         .then((response: any) => {
               
        //         })
        //         .catch((e: Error) => {
        //         console.log(e);
        //     });
        // }

    }
    const resetCampaign = (id:any) => {
        changeOpen(!open)
        CampaignConfigService.getCampaign(id)
                .then((response: any) => {
                    setCampaign(response.data)
                    const temp = response.data.camp_budget
                    setTiming([...temp])
                    var total = 0
                    for (let i = 0; i < timing.length; i++) {
                        total += parseInt(timing[i].budget);
                    }
                    setBudget(response.data.budget_per_day)
                    // openToast({ message: "Campaign Deleted" })
                    // window.location.reload();
                })
                .catch((e: Error) => {
                console.log(e);
            });
    }
    const resetCustomCampaign = (id:any) => {
        changeOpen(!open)
        CampaignConfigService.getCampaign(id)
                .then((response: any) => {
                    setCampaign(response.data)
                    const temp = response.data.camp_budget
                    setTiming([...temp])
                    var total = 0
                    for (let i = 0; i < timing.length; i++) {
                        total += parseInt(timing[i].budget);
                    }
                    setBudget(response.data.budget_per_day)
                    // openToast({ message: "Campaign Deleted" })
                    // window.location.reload();
                })
                .catch((e: Error) => {
                console.log(e);
            });
    }

    const saveCampaign = () =>{
        campaign.name = "default";
        campaign.budget_per_day = budget;
        campaign.active = cam_status;
        campaign.status = '1';
        campaign.from_date = "";
        campaign.to_date = "";
        campaign.camp_budget = [...timing]
        var temp = 0
        for (let i = 0; i < timing.length; i++) {
            temp += parseInt(timing[i].budget);

            if(timing[i].from_time === "" && timing[i].minimum === "" && timing[i].maximum === "" && timing[i].to_time === "" && timing[i].budget === ""){
                alert("Data Empty Field")
                return false;
            }

            if(timing[i].maximum < timing[i].minimum){
                alert("Minimum Awarded greater then Maximum")
                return false;
            }

            if(timing[i].budget < timing[i].minimum){
                alert("Minimum Awarded greater then Budget")
                return false;
            }

            if(timing[i].budget < timing[i].maximum){
                alert("Maximum Awarded greater then Budget")
                return false;
            }
        }
        
        
        if(temp != campaign.budget_per_day){
            alert("budget Calculation Mismatched ")
            return false;
        }
        
        


        if(editDefault){
            CampaignConfigService.update(campaign.id,campaign)
                .then((response: any) => {
                    openToast({ message: "Campaign Setting Updated" })
                })
                .catch((e: Error) => {
                console.log(e);
            });
        }else{
            CampaignConfigService.newRequest(campaign)
                .then((response: any) => {
                    openToast({ message: "New Campaign Created" })
                })
                .catch((e: Error) => {
                console.log(e);
            });
        }

    }
    // const getValue = () =>{
    //     CampaignConfigService.get()
    //     .then((response: any) => {
            
    //       if(response.data.length == 1){
    //             setCampaign(response.data[0]);
    //             setBudget(response.data[0].budget_per_day);
    //             setTiming(response.data[0].camp_budget);
    //             changeEditDefault(true);
    //         }   
            
    //     })
    //     .catch((e: Error) => {
    //       console.log(e);
    //     });
    // }
    // useEffect(() => {
    //     setTiming(campaign.camp_budget)
        
    //   },[]);
    const commonOptions = [
        { 'icon': 'edit', 'label': t('edit'), 'click': customOpenChange },
        { 'icon': 'delete', 'label': t('delete'), 'click': customDelete },
        { 'icon': 'duplicate', 'label': t('duplicate'), 'click': customDuplicate }
    ];
    const defaultOptions = [
        { 'icon': 'edit', 'label': t('edit'), 'click': customOpenChange }
    ];

    const editOptions = [
        { 'icon': 'delete', 'label': t('delete'), 'click': customDelete },
        { 'icon': 'duplicate', 'label': t('duplicate'), 'click': customDuplicate }
    ];

    const editCampaignName = (value:any) => {
        
        changeEditCampaign(!editCampaign)
    }

    const displayRow = (row:any,index:any) =>{
       
       return <StyledTableRow key={index}>
                                            <StyledTableCell>
                                                <div className='customToggle'>
                                                    <IconButton aria-label="add item" >
                                                        <RemoveIcon onClick={(e)=>handleRemoveRow(index,row)} />
                                                    </IconButton>
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <div className='customTimer'>
                                                    
                                                    <CustomTime value={row.from_time} onChange={changeTiming} returnParams={{index:index,type:'fromTime'}} options={{ OpenPickerIcon: ArrowDropDownIcon,maxTime:row.to_time?row.to_time:''}} />
                                                    
                                                </div>
                                            </StyledTableCell>

                                            <StyledTableCell>
                                                <div className='customTimer'>
                                                    <CustomTime value={row.to_time} onChange={changeTiming} returnParams={{index:index,type:'toTime'}} options={{ OpenPickerIcon: ArrowDropDownIcon, minTime:row.from_time?row.from_time:''  }} />
                                                </div>
                                            </StyledTableCell>

                                            <StyledTableCell><input type="text" value={row.budget} onChange={(e)=>changeTiming(e.target.value,{index:index,type:'budget'})} className='uc-formControl' /></StyledTableCell>

                                            <StyledTableCell><input type="text" value={row.minimum} onChange={(e)=>changeTiming(e.target.value,{index:index,type:'minimum'})}  className='uc-formControl'  /></StyledTableCell>

                                            <StyledTableCell><input type="text" value={row.maximum} onChange={(e)=>changeTiming(e.target.value,{index:index,type:'maximum'})}  className='uc-formControl'  /></StyledTableCell>

                                        </StyledTableRow>
    }


    // const items = [
    //     createData(
    //         <div className='customToggle'>
    //             <IconButton aria-label="add item">
    //                 <RemoveIcon />
    //             </IconButton>
    //         </div>,
    //         <div className='customTimer'><CustomTime value={time} onChange={changeFromTiming} returnParams={{index:1,type:'fromdate'}} options={{ OpenPickerIcon: ArrowDropDownIcon }} /></div>,
    //         <div className='customTimer'><CustomTime value={time} onChange={changeTiming} returnParams={{index:1,type:'todate'}}options={{ OpenPickerIcon: ArrowDropDownIcon }} /></div>,
    //         <input type="text" className='uc-formControl' />,
    //         <input type="text" className='uc-formControl' />
    //      )
    //     // createData(
    //     //     <div className='customToggle'>
    //     //         <IconButton aria-label="add item">
    //     //             <RemoveIcon />
    //     //         </IconButton>
    //     //     </div>,
    //     //     <div className='customTimer'><CustomTime value={time} onChange={changeTiming} returnParams={{index:2}} options={{ OpenPickerIcon: ArrowDropDownIcon }} /></div>,
    //     //     <div className='customTimer'><CustomTime value={time} onChange={changeTiming} returnParams={{index:2}} options={{ OpenPickerIcon: ArrowDropDownIcon }} /></div>,
    //     //     <input type="text" className='uc-formControl' />,
    //     //     <input type="text" className='uc-formControl' />
    //     // ),
    //     // createData(
    //     //     <div className='customToggle'>
    //     //         <IconButton className='addButton' aria-label="remove item">
    //     //             <AddIcon />
    //     //         </IconButton>
    //     //     </div>,
    //     //     <div className='customTimer'><CustomTime value={time} onChange={changeTiming} returnParams={{index:3}} options={{ OpenPickerIcon: ArrowDropDownIcon }} /></div>,
    //     //     <div className='customTimer'><CustomTime value={time} onChange={changeTiming} returnParams={{index:3}} options={{ OpenPickerIcon: ArrowDropDownIcon }} /></div>,
    //     //     <input type="text" className='uc-formControl' />,
    //     //     <input type="text" className='uc-formControl' />
    //     // ),

    // ];
    var today = new Date();
    today.setHours(0,0,0,0);
    var today_ts = today.getTime();
    
    const handleAddRow = () => {
        const a = new Date(parseInt(timing[timing.length - 1].to_time))
        if(a.getHours() !== 23 || a.getMinutes() !== 59){
            if((timing[timing.length - 1].to_time) != ""){
                let data = timing;
                let time = {
                    from_time:dayjs((timing[timing.length - 1].to_time)+60000).valueOf(),
                    to_time:"",
                    budget:"",
                    minimum:"",
                    maximum:"",
                }
                data.push(time)
                setTiming([...data]);
            }
        }
        
    };
    const handleRemoveRow = (index:any,item:any) => {

        if(timing.length !== 1){
            var data = timing;
            let id = data[index].id
            data.splice(index, 1);
            setTiming([...data]);
        }else{
            setColor("warning")
            openToast({ message: "Not Possiable to Remove" })
        }
        
    }
    
    return (
        
            <div className="campaign-setting-block">
                <div className="campaign-setting-header">
                    <div className="campaign-header-lt">
                        <FormControlLabel className="activityLabel" control={<Android12Switch defaultChecked={cam_status=='1'} /*disabled = {campChange}*/ onChange={e => statusChange(e.target.checked)}/>} label="" 
                        />
                        <div className="campaign-name">
                            {defaultCampaign && <h4>{t("activate_default_setting")}</h4>}
                            {!defaultCampaign &&
                                ((editCampaign === false || campChange === true) && today_ts<campaignStartDate ? 
                                <>
                                    <div className='edit-campaign-name'>
                                    <input onChange={(e)=>changeCampName(e.target.value)} defaultValue={campaignName} />
                                        <a href="javascript:void(0)" onClick={editCampaignName}>
                                            <img src={process.env.PUBLIC_URL + 'assets/img/admin/tick_icon.svg'} alt="" />
                                        </a>
                                    </div>
                                </> : 
                                <h4>{campaignName} 
                                    <a href="javascript:void(0)" onClick={editCampaignName}>
                                        <img src={process.env.PUBLIC_URL + 'assets/img/admin/edit.svg'} alt="" />
                                    </a> 
                                </h4>)
                            }
                        </div>
                    </div>
                    <div className="campaign-header-rt">
                        {!defaultCampaign &&
                            <>
                            {
                            today_ts<campaignStartDate?
                            <span className='campaign-badge upcoming'>Upcoming</span>
                            :
                            today_ts<campaignEndDate?
                            <span className='campaign-badge onlive'>On-Live</span>
                            :
                            <span className='campaign-badge offline'>Off-Line</span>
                            }
                            <div className="campaign-setting-date">
                                    <p>{t("date")}:</p>
                                    <div className="date-picker-wrapper">
                                        <div className="date-picker">
                                        <CustomDate value={campaignStartDate} onChange={changeStartDate} returnParams={{index:campaignStartDate,type:'start'}} options={{ minDate: new Date(),maxDate: campaignEndDate, OpenPickerIcon: ArrowDropDownIcon }} />
                                            {/* <CustomDate value={campaignStartDate} onChange={setCampaignStartDate} options={{ maxDate: campaignEndDate, OpenPickerIcon: ArrowDropDownIcon }} /> */}
                                        </div>
                                        <div className="date-picker">
                                        <CustomDate value={campaignEndDate} disabled = {campChange} onChange={changeEndDate} returnParams={{index:campaignEndDate,type:'end'}}options={{ minDate: campaignStartDate, OpenPickerIcon: ArrowDropDownIcon }} />
                                            {/* <CustomDate value={campaignEndDate} disabled = {campChange} onChange={setCampaignEndDate} options={{ minDate: campaignStartDate, OpenPickerIcon: ArrowDropDownIcon }} /> */}
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="campaign-budget">
                            <h4>{t("ucash_per_day")}
                               <span className='budget'><CurrencyRupeeIcon />{budget}</span>
                            </h4>
                        </div>
                        {!defaultCampaign?<CustomStyleMenu items={open?editOptions:commonOptions}  values={{ id:campaign }}/>:<CustomStyleMenu items={defaultOptions}  values={{ id:campaign }}/>}
                    </div>
                </div>
                {open &&
                    <div className="campaign-setting-body">
                        <TableContainer component={Paper} className="sa-create-camp">
                            <Table sx={{ maxWidth: 677 }} aria-label="customized table" className="activityTabel">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>
                                            <div className='customToggle' >
                                                 <IconButton className='addButton' /*disabled={!campChange}*/ aria-label="remove item">
                                                    <AddIcon onClick={handleAddRow} />
                                                </IconButton>
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell>{t("from_time")}</StyledTableCell>
                                        <StyledTableCell>{t("to_time")}</StyledTableCell>
                                        <StyledTableCell>{t("budget")}</StyledTableCell>
                                        <StyledTableCell>{t("min_award")}</StyledTableCell>
                                        <StyledTableCell>{t("max_award")}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                    [...timing].map((row:any,index:any) => (
                                                
                                        
                                        
                                        // <StyledTableRow key={index}>
                                        //     <StyledTableCell>
                                        //     { 
                                        //         index+1 == timing.length ? 
                                        //     <div className='customToggle'>
                                        //          <IconButton className='addButton' aria-label="remove item">
                                        //             <AddIcon onClick={handleAddRow} />
                                        //         </IconButton>
                                        //     </div>
                                        //     :
                                        //     <div className='customToggle'>
                                        //         <IconButton aria-label="add item">
                                        //             <RemoveIcon onClick={(e)=>handleRemoveRow(index)} />
                                        //         </IconButton>
                                        //     </div>
                                            
                                        //     }
                                        //     </StyledTableCell>
                                        //     <StyledTableCell>
                                        //         <div className='customTimer'>
                                        //             <CustomTime value={fromTime} onChange={changeTiming} returnParams={{index:1,type:'fromdate'}} options={{ OpenPickerIcon: ArrowDropDownIcon }} />
                                        //         </div>
                                        //     </StyledTableCell>
                                        //     <StyledTableCell>
                                        //         <div className='customTimer'>
                                        //             <CustomTime value={toTime} onChange={changeTiming} returnParams={{index:1,type:'todate'}}options={{ OpenPickerIcon: ArrowDropDownIcon }} />
                                        //         </div>
                                        //     </StyledTableCell>
                                        //     <StyledTableCell><input type="text" className='uc-formControl' /></StyledTableCell>
                                        //     <StyledTableCell><input type="text" className='uc-formControl' /></StyledTableCell>
                                        // </StyledTableRow>
                                        displayRow(row,index)
                                        
                                    ))}
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {defaultCampaign ?<div className="campiagn-action-wrap">
                            <Button variant="outlined" onClick={(e) => resetCampaign(campaign.id)} color="primary">
                                {t("cancel")}
                            </Button>
                            <Button variant="contained" onClick={saveCampaign} color="primary">
                                {t("save")}
                            </Button>
                        </div>
                        :
                        <div className="campiagn-action-wrap">
                            <Button variant="outlined" onClick={(e) => resetCustomCampaign(campaign.id)}  color="primary">
                                {t("cancel")}
                            </Button>
                            <Button variant="contained" onClick={saveCustomCampaign} color="primary">
                                {t("save")}
                            </Button>
                        </div>
                        }

                    </div>
                }
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={toast.isOpen} >
                        <Alert  severity={color} sx={{ width: '100%' }}>
                        {toast.message}
                        </Alert>
                    </Snackbar>
                </Stack>
            </div>
        
    )
}

export default Campaign

