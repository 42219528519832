
import React, { FC, forwardRef, useImperativeHandle, useState } from "react";
import OtpInput from 'react-otp-input';
import tokenService from '../services/token_service'
// const [otpvalue,setOtpValue] = React.useState('')

export interface OTPInputRef {
    clear: () => void;
  }
interface OTPInputProps {

}
const OTPInput= forwardRef<OTPInputRef, OTPInputProps> ((props,ref) => {
    const [otp, changeOTP] = useState(tokenService.getOtp());
    useImperativeHandle(ref, () => ({
        clear: () => {
            changeOTP('');
        },
      }));
    
    const updateOTP = (otp:any) => {
        changeOTP(otp)
        tokenService.setotp(otp)
        // setOtpValue(otp)
        // props.updateOTP(otp)
    }

    return (
        <OtpInput
        value={otp}
        onChange={updateOTP}
        numInputs={4}       
        shouldAutoFocus={true}
        className="square-box"
        inputStyle={"digitsInput"}
        containerStyle={"OTPInputsForm"}
        isInputNum={true}
      
        // isDisabled={isFormDisabled}
        />
    );
});

export default OTPInput;
