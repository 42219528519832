import React, { ReactElement, FC, useState, useEffect, useRef} from "react";
import Accordion from "../components/Accordion/Accordion";
import { useModal } from '../components/Modal/useModal'
import './pages.css';
import UserDataService from "../services/user_service";
import '../App.css';
import { Link } from "react-router-dom";
import '.././components/Radio/radiobutton.css';
import { useTranslation } from "react-i18next";
import Snackbar from '@mui/material/Snackbar';
import config from '../config/config.json';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import tokenService from '../services/token_service';
import AuthService from "../services/auth_service";
import {  useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import MuiAlert, { AlertProps,AlertColor } from '@mui/material/Alert';

const ProfilePreference: FC<any> = (): ReactElement => {
  var md5 = require('md5');
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const defaultToast = { isOpen: false, message: ""}
  const [toast, setToast] = useState(defaultToast);
  const [color, setColor] = useState<AlertColor>("success");
  const toastAutoHideTime = 1000;
  const openToast = (options:any) => {
    setToast({
      isOpen: true,
      message: options.message ? options.message : ""
    })
    setTimeout(function(){
      setToast({ isOpen: false, message: ""})
    }, toastAutoHideTime)
  }
  const id = localStorage.getItem('user_token');
  const [newsLetter, changeNewsLetter] = useState<any>();
  const [error, setError] = useState<any>({status:'vaild',code:0});
  const [errorPassword, setErrorPassword] = useState<any>({status:'vaild',code:0});
  const [errorDelete, setErrorDelete] = useState<any>({status:'vaild',code:0});

  const [currentUser, setCurrentUser] = useState<any>({});
  const [blockedUsers, setBlockedUsers] = useState<any>([]);
  const getUser = (id: any) => {
    UserDataService.get()
      .then((response: any) => {
        setCurrentUser(response.data);
        changeRole(response.data.new_role_id);
        setError({status:'vaild',code:0})
        //changeRole(response.data.new_role_id);
      })
      .catch((e: Error) => {
        
      });
  };
  const [role, changeRole] = useState(currentUser?.new_role_id);
  const getBlockedUser = (id: any) => {
    UserDataService.getBlockedUser(id)
      .then((response: any) => {
        setBlockedUsers(response.data);
      })
      .catch((e: Error) => {
        
      });
  };

  const getUserPreference = (id: any) => {
    UserDataService.getUserPreference(id)
      .then((response: any) => {
        var result = response.data.data
        changeNewsLetter(result.newsletter === 1 ? true : false);
      })
      .catch((e: Error) => {
        
      });
  }
  const updateUserPreference = () => {
    changeNewsLetter(!newsLetter);
    UserDataService.updateUserPreference(id, {'newsLetter': !newsLetter, 'type': 'newsletter'})
      .then((response: any) => {
        if(!newsLetter)
          openToast({ message: "Newsletter Subscribe Successfully" })
        else
          openToast({ message: "Newsletter Unsubscribe Successfully" })
      })
      .catch((e: Error) => {
      });
  }
  
  useEffect(() => {
    
        getUser(id);
        getBlockedUser(id);
        getUserPreference(id);
    
  }, []);

  const handleInputChange = (e: any) => {
    const name =e.target.name;
    const value =e.target.value;
    if(name === 'profile_url'){
      let vaild = value?.match(/^([ A-Za-z0-9_.])*$/)
      if(vaild !== null){
        setCurrentUser({ ...currentUser, [name]: value });
        setError({status:'vaild',code:0})
      }else{
        setError({status:'invaild URL',code:1})
        setCurrentUser({ ...currentUser, [name]: value });
      }
      
    }
    
  };

  const updateUser = (type1:any) => {
    currentUser.type = 'url'
    if(currentUser.profile_url === '' || currentUser.profile_url === null){
      setError({status:'Profile URL is empty',code:1})
      return false
    }
    if(currentUser.profile_url?.match(/^([ A-Za-z0-9_.]{2,})*$/) === null){
      setError({status:'Profile URL is Invaild',code:1})
      return false
    }
    UserDataService.update(currentUser)
      .then((response: any) => {
        if(response.data.type === 'error'){
          setError({status:response.data.message,code:1})
        } else{
         setColor("success")
         openToast({ message: response.data.message})
         getUser(id)
        }
      })
      .catch((e: Error) => {
        console.log(e)
      });
  };
  const navigate = useNavigate();
  const [setOptions, changeOptions] = useState<any>("");
  const [value, setValue] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const { show: showDelete1, hide: hideDelete1, RenderModal: DeleteModel1 } = useModal();
  const { show: showOption, hide: hideOption, RenderModal: OptionModel } = useModal();
  const { show: showDeleted, hide: hideDeleted, RenderModal: DeletedModel } = useModal();
  const inputPhone = useRef<HTMLInputElement | null>(null)
  const otherOptions = useRef<HTMLInputElement | null>(null)
  const { show: showConfirmPassword, hide: hideConfirmPassword, RenderModal: ConfirmPassword } = useModal();
  const { RenderModal: Showdevicepopup } = useModal();
  const options = [
    { 'value': 'I have a duplicate account', 'name': 'roles_1', 'label': 'I have a duplicate account', 'class': 'privacy-group', 'type': 'radio' },
    { 'value': 'I am getting too many emails', 'name': 'roles_1', 'label': 'I\'m getting too many emails', 'class': 'privacy-group', 'type': 'radio' },
    { 'value': 'I am not getting any value from my membership', 'name': 'roles_1', 'label': 'I\'m not getting any value from my membership', 'class': 'privacy-group', 'type': 'radio' },
    { 'value': 'I have privacy concern', 'name': 'roles_1', 'label': 'I have privacy concern', 'class': 'privacy-group', 'type': 'radio' },
    { 'value': 'I am receiving unwanted contacts', 'name': 'roles_1', 'label': 'I\'m receiving unwanted contacts', 'class': 'privacy-group', 'type': 'radio' },
    { 'value': 'Others', 'name': 'roles_1', 'label': 'Others', 'class': 'privacy-group others-option', 'type': 'radio' }
  ];
  const Deletestep1 = () =>{
      hideDelete1();
      changeOptions("");
      showOption();
  }
  const DeleteLastStep = () =>{
    if(setOptions === ''){
      setErrorDelete({status:'Please select a reason',code:1})
      return false
    }
    if(setOptions === 'Others'){
      if(otherOptions.current?.value === ''){
        const divElement = document.getElementById('optionError'); // Get the div element by its id
        if (divElement) {
          divElement.style.display = 'block'; // Show the div
          divElement.innerText = 'Please enter the reason'; 
        }
        return false
      }
      changeOptions(otherOptions.current?.value);
    }
    hideOption();
    showConfirmPassword();
  }
  

  const roleGroup = [
    { 'value': 1, 'name': 'new_role_id', 'label': 'Student', 'class': 'role_group', 'type':'radio' },
    { 'value': 2, 'name': 'new_role_id', 'label': 'Educator', 'class': 'role_group', 'type':'radio' },
    { 'value': 3, 'name': 'new_role_id', 'label': 'Professional', 'class': 'role_group', 'type':'radio' },
    { 'value': 4, 'name': 'new_role_id', 'label': 'Others', 'class': 'role_group', 'type':'radio' },
  ];

  const radioChangeHandler = (event: any) => {
    
    setCurrentUser({ ...currentUser, 'new_role_id': Number(event.target.value) });
    changeRole(Number(event.target.value));
    setCurrentUser({ ...currentUser, 'type': "role" });
  }
  const optionSelect = (event: any) => {
    changeOptions(event.target.value);
    setErrorDelete({status:'',code:0})
  }
  const handleOptionInputChange = () => {
    if(setOptions === 'Others'){
      if(otherOptions.current?.value !== ''){
        const divElement = document.getElementById('optionError'); // Get the div element by its id
        if (divElement) {
          divElement.style.display = 'none'; // Hide the div
        }
      }
    }
  }
  const handlePasswordInputChange = () => {
      if(inputPhone.current?.value !== ''){
        const divElement = document.getElementById('passwordError'); // Get the div element by its id
        if (divElement) {
          divElement.style.display = 'none'; // Hide the div
        }
      }
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const logout = () => {
    AuthService.logout()
    .then((response: any) => {
      console.log(response.data);
      localStorage.clear();
      tokenService.set('')
      navigate('/')
      window.location.reload();
    })
    .catch((e: Error) => {
      console.log(e)
    });
    
  }
  const Deleted = () =>{
    hideDeleted();
    logout();
  }
  const ConfirmDelete = () =>{
    let password = inputPhone.current?.value;
    if(password === ''){
      const divElement = document.getElementById('passwordError'); // Get the div element by its id
        if (divElement) {
          divElement.style.display = 'block';
          divElement.innerText = 'Password is empty'; // Hide the div
        }
      return false
    }
    const data = {'reason': setOptions,'password':md5(password)};
    UserDataService.deleteAccount(data)
        .then((response: any) => {
          console.log(response.data);
          if(response.data.data.status !== 'failed'){
            hideConfirmPassword();
            showDeleted();
          }else{
            const divElement = document.getElementById('passwordError'); // Get the div element by its id
            if (divElement) {
              divElement.style.display = 'block';
              divElement.innerText = response.data.data.response; // Hide the div
            }
            return false
          }
        })
        .catch((e: Error) => {
        });
    
  }
  const updateuLektzRole = () => {
    currentUser.new_role_id = role
    currentUser.type = 'role'
    UserDataService.update(currentUser)
      .then((response: any) => {
        openToast({ message: response.data.message })
        // alert("Profile Updated ")
      })
      .catch((e: Error) => {
        
      });
  };
  const { t } = useTranslation();
  const testFunc = ( data:any ) => {
    //alert(data);
    getUser(id)
  }
  return (
    <>
      <div className="ua-common-wrap">
        <div className="ua-accordion-wrap">
          <div className="ua-account-details">
            {/* <Accordion onChange={testFunc} title={['Profile URL', 'Role in uLektz', 'Blocked Users']} > */}
            <Accordion onChange={testFunc} title={['Profile URL', 'Role in uLektz']} >
              <div className='acc0'>
              {currentUser ? (
            <form>
                <div className="choice-sec choice-sec-new">
                  <label>
                    {t('ulektz_com')}
                    <div className="ua-user-prof">
                      <input type="text" name="profile_url" id="profile_url" onChange={handleInputChange}value={currentUser.profile_url} />
                      {error.code === 1 && <div className="error">{error.status}</div>}
                    </div> 
                  </label>                  
                  {/* <p>{t('note')} <span className="notes-decor">{t('note_text')}</span></p> */}
                  <div className="button-part">
                    <button type="button" className="btn-work cancel-btn" onClick={(e)=>getUser(id)}>{t('Reset')}</button>
                    <button type="button" className="btn-work save-btn" disabled={error.code === 1?true:false} onClick={(e)=>updateUser("url")}>{t('save_changes')}</button>
                  </div>
                </div>
              </form>
                   ) : (
                    <>
                    </>
                  )}
              </div>
              <div className='acc1'>
                <div className="choice-sec">
                  <>
                    <div className='groupClass'>
                        {
                        roleGroup.map((item, index) =>{
                            
                            return (
                              //   <RadioButton
                              //   changed={radioChangeHandler}
                              //   id={index}
                              //   isSelected={item.value == role}
                                
                              //   label={item.label}
                              //   value={item.value}
                              //   type={item.type}
                              //   name={item.name}
                              //   class={item.class}
                              // />

                              <div className={'md-radio '+item.class}>
                              <input name={item.name} id={item.name + '_' + index} value={item.value} type={item.type} checked={item.value === role} onChange={radioChangeHandler} />
                              <label htmlFor={item.name + '_' + index}>{item.label}</label>
                          </div>
                            )
                        }
                      )
                    }
                    </div>
                  </>
                  <div className="button-part">
                    <button type="button" onClick={(e)=>getUser(id)} className="btn-work cancel-btn">{t('Reset')}</button>  
                    <button type="button" onClick={updateuLektzRole} className="btn-work save-btn">{t('save_changes')}</button>
                  </div>
                </div>
              </div>
              {/* <div className='acc2'>
                <p className="accordian-sub">{t('block_text')}</p>
                {
                    blockedUsers.map((item:any) => 
                      <>
                        
                        <div className="accord-straight-div">
                            <div className="profile">

                            {item.userprofile !== '' || item.userprofile !== null && 
                            <img src={'https://s3.ap-south-1.amazonaws.com/ulektzmumbai/'+item.userprofile} className="" alt="Profile" />
                            }
                            {item.userprofile ==='' || item.userprofile === null && 
                            <img src={process.env.PUBLIC_URL + 'assets/img/user.png'} className="" alt="Profile" />
                            }
                            <div className="img-details">
                                <span>{item.username}</span>
                                <p>
                                <img src={process.env.PUBLIC_URL + 'assets/img/location.svg'} className="" alt="Profile" />
                                {item.usercity?item.usercity+', ':''}{item.usercountry}</p>
                            </div>
                            </div>
                            <p className="b-date">{item.useremail}</p>
                            <p className="b-date">Blocked on {item.blockedon}</p>
                            { <div className="uc-ublock-div">
                                <a href="javascript:void(0)" onClick={showdevicebtn} className="btn-work cancel-btn unblock-popup-link">
                                <img src={process.env.PUBLIC_URL + 'assets/img/Unblock-icon.svg'} alt="Unblock-icon" />
                                {t('unblock')}</a>
                            </div> }
                        </div>
                      </>
                    )
                }
              </div> */}
            </Accordion>
            <div className="accordian-sep-strip">
              <h3>{t('subscribe_text')}</h3>
              <label htmlFor="subscribe" className="subscribe-checkbox">
                <input value="" type="checkbox" checked={newsLetter} onChange={updateUserPreference} id="subscribe" />
                <span></span>
              </label>
            </div>
            <div>
              <div className="button-part" >
                <Link className="delete-btn" to="/deleteAccount">{t('delete_account')}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Showdevicepopup>
        <div className="uc-email-otp-enter-popup">
          <div className="uc-add-email-popup">
            <div className="uc-modal-wrapper uc-modal-transition uc-modal-xs-wrapper">
              <div className="uc-modal-header pop-up-head">
                <h2>Unblock Contact</h2>
              </div>
              <div className="uc-modal-body">
                <div className="uc-msg-body pop-up-body">
                  <p>Are you sure that you want to Unblock David Mathew?</p>
                </div>
                <div className="uc-tab-btn pop-up-bottom">
                  <button type="button" className="ma-cancel">Cancel</button>
                  <button type="button" className="btn-work green-bg email-otp-enter-popup-link">Unblock</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Showdevicepopup>
      <DeleteModel1>
        <div className="uc-del-account-popup">
          <div className="uc-modal-wrapper uc-modal-transition uc-modal-md-wrapper">
          <a href="javascript:void()" className="uca-close-mod" onClick={hideDelete1}></a>
            <div className="uc-modal-header pop-up-head">
            <div className="ua-me-profile-img" >
                <img src={config.uLektz_Profile_Image_Path+ localStorage.getItem('user_image')} className="" alt="shopping cart"  onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src=process.env.PUBLIC_URL + 'assets/img/common-user.svg';
                }}/>
                <h5>{localStorage.getItem('user_fname') + ' ' + localStorage.getItem('user_lname')}</h5>
                  <p>{localStorage.getItem('user_email')}</p>
            </div>
            </div>
            <div className="uc-modal-body">
              <div className="uc-msg-body pop-up-body">
                <h3>{t('delete_head')}</h3>
                <p>{t('delete_desc_1')}</p>
              </div>
              <div className="uc-tab-btn pop-up-bottom">
                <button type="button" className="btn-work red-bg" onClick={Deletestep1}>{t('delete')}</button>
              </div>
            </div>
          </div>
        </div>
      </DeleteModel1>
      <OptionModel>
        <div className="uc-del-account-option-popup">
          <div className="uc-modal-wrapper uc-modal-transition uc-modal-md-wrapper">
          <a href="javascript:void()" className="uca-close-mod" onClick={hideOption}></a>
            <div className="uc-modal-body">
              <div className="uc-msg-body pop-up-body">
                <h3><span>{localStorage.getItem('user_fname') + ' ' + localStorage.getItem('user_lname')+", we're Sorry to see you go"}</span></h3>
                <p>Tell us the reason why you are closing your account</p>
                {
                  options.map((item, index) =>{
                    return (
                      <div className={'md-radio '+item.class}>
                        <input name={item.name} id={item.name + '_' + index} value={item.value} type={item.type} checked={item.value === setOptions} onChange={optionSelect} />
                        <label htmlFor={item.name + '_' + index}>{item.label}</label>
                        {
                          setOptions === 'Others' && item.value === 'Others'?
                          <input type="text" placeholder="" ref={otherOptions} onChange={handleOptionInputChange} defaultValue={otherOptions.current?.value}/>
                          :''
                        }
                      </div>
                    )
                  }
                )
              }
              {errorDelete.code === 1 && <div className="error">{errorDelete.status}</div>}
              <div id="optionError" className="error" style={{ display: 'none' }}>{t('wrong_code')}</div>
              </div>
              <div className="uc-tab-btn pop-up-bottom">
                <button type="button" className="btn-work save-btn" onClick={DeleteLastStep}>{t('Next')}</button>
              </div>
            </div>
          </div>
        </div>
      </OptionModel>
      <ConfirmPassword>
        <div className="uc-email-del-enter-popup">
          <div className="uc-add-email-popup">
            <div className="uc-modal-wrapper uc-modal-transition uc-modal-md-wrapper">
            <a href="javascript:void()" className="uca-close-mod" onClick={hideConfirmPassword}></a>
              <div className="uc-modal-header pop-up-head">
                <h2>Confirm Your Password</h2>
              </div>
              <div className="uc-modal-body">
                <div className="uc-msg-body pop-up-body">
                  <p className="sub-text-body">Complete you delete request by entering the password associated with you account</p>
                  <div className="body-input">
                    <label>Password</label>
                                <input type={showPassword?"text":"password"} id="addmobile" ref={inputPhone} placeholder="" onChange={handlePasswordInputChange} defaultValue={inputPhone.current?.value} autoFocus/>
                                  <span className="btn-icon"><InputAdornment position="end">
                                      <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                      >
                                      {!showPassword ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                  </InputAdornment></span>
                                
                  </div>
                  <div id="passwordError" className="error" style={{ display: 'none' }}></div>
                  {/* {errorPassword.code === 1 && <div className="error">{errorPassword.status}</div>} */}
                </div>
                
                <div className="uc-tab-btn pop-up-bottom">
                  <button type="button" className="btn-work red-bg email-otp-enter-popup-link" onClick={ConfirmDelete}>{t('Delete')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ConfirmPassword>
      <DeletedModel>
        <div className="uc-del-account-confirmed-popup">
          <div className="uc-modal-wrapper uc-modal-transition uc-modal-sm-wrapper">
            <div className="uc-modal-body">
            <div className="ua-me-profile-img">
                <img src="assets/img/Remove_icon.svg" className="" alt="shopping cart"  onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src=process.env.PUBLIC_URL + 'assets/img/user.png';
                }}/>
              </div>
              <div className="uc-msg-body pop-up-body">
                <h3>{t('Your Account is Deleted')}</h3>
                <p>{t('Sorry, to see you go')}</p>
              </div>
              <div className="uc-tab-btn pop-up-bottom">
                <button type="button" className="btn-work save-btn" onClick={Deleted}>{t('Ok')}</button>
              </div>
            </div>
          </div>
        </div>
      </DeletedModel>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={toast.isOpen} >
          <Alert  severity={color} sx={{ width: '100%' }}>
            {toast.message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default ProfilePreference;
