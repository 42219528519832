import React from 'react';
import Layout from "./layouts/layout";
import Login from "./layouts/login";
import tokenService from './services/token_service';
import { Cookies } from 'react-cookie';
import Wallet from './pages/Wallet';
import Deactive from './pages/Deactive'
import './App.css';
import './pages/super_admin.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
function App() {
    // const userToken = localStorage.getItem('user_token');
    const cookies = new Cookies();
    const userToken = cookies.get('ulektz_token');

    return (
        <div className="App">
            <div className='my-wrapper'>
            <div id='modal-root' />
       
      
            <Routes>
                <Route path="/" element={ userToken && userToken !== '' ?<Layout /> : <Login /> }/>
                <Route path="/Wallet" element={ userToken && userToken !== '' ? <Wallet/>: <Login /> }/>
                <Route path="/deleteAccount" element={ userToken && userToken !== '' ? <Deactive/>: <Login /> }/>
            </Routes>
        
            </div>
        </div>
    );
}

export default App;
