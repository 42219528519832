import axios from "axios";
import config from '../config/config.json';
import tokenService from '../services/token_service';


// export default axios.create({
//   baseURL: config.REACT_APP_BASE_URL,
//   headers: {
//     "Content-type": "application/json",
//     // 'Authorization':`Bearer ${tokenService.get()}`
//   }
// });
export default axios.create({
  //baseURL: "http://ec2-13-127-12-155.ap-south-1.compute.amazonaws.com/api/v1",
  baseURL: config.REACT_APP_BASE_URL,
  headers: {
    "Content-type": "application/json"
  }
});


// import axios from 'axios';


const ACCESS_TOKEN = 'access_token'
const REFRESH_TOKEN = 'refresh_token'

let tokenRequest = axios.create({
    baseURL: config.REACT_APP_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    }
})

const refreshToken = () => {
  const refreshBody = {"refresh": window.localStorage.getItem(REFRESH_TOKEN)}
  return tokenRequest.post(`/api/token/access/`, refreshBody)
    .then((response)=> {
      window.localStorage.setItem(ACCESS_TOKEN, response.data.access);
      return Promise.resolve(response.data);
    }).catch((error)=>{
      return Promise.reject(error);
    });
}

/*
 * authRequest
 *
 * This refreshes the request and retries the token if it is invalid.
 * This is what you use to create any requests that need the Tokens.
 * Reference: https://hackernoon.com/110percent-complete-jwt-authentication-with-django-and-react-2020-iejq34ta
 *
 * Example:
 *     authRequest.get('/path/to/endpoint/',extraParameters)
 *        .then(response=>{
 *          // do something with successful request
 *        }).catch((error)=> {
 *          // handle any errors.
 *        });
*/

const authRequest = axios.create({
    baseURL: config.REACT_APP_BASE_URL,
    headers: {
      'Authorization': `Bearer ${tokenService.get()}`,
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    
});

export { tokenRequest, refreshToken, authRequest, ACCESS_TOKEN, REFRESH_TOKEN }