import React, { ReactElement, FC, useState } from "react";
import '../context/login.css';
import AuthService from "../services/auth_service";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
// import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Carousel from 'react-material-ui-carousel';
import GoogleLogin from 'react-google-login';
import Snackbar from '@mui/material/Snackbar';
import tokenService from '../services/token_service';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from "axios";


function LoginSlider(props: any) {
    var items = [
        {
            name: "Connect",
            description: "with peers and experts",
            image: process.env.PUBLIC_URL + 'assets/img/Connect-img.svg'
        },
        {
            name: "Access",
            description: "exclusive digital resources",
            image: process.env.PUBLIC_URL + 'assets/img/Access-img.svg'
        },
        {
            name: "Share",
            description: "Knowledge and experience",
            image: process.env.PUBLIC_URL + 'assets/img/Share-img.svg'
        }
    ]

    return (
        <Carousel navButtonsAlwaysInvisible={true} height={360} indicatorIconButtonProps={{ className: 'normalButton' }} activeIndicatorIconButtonProps={{ className: 'activeButton' }}>
            {
                items.map((item, i) => <Item key={i} item={item} />)
            }

        </Carousel>
    )
}

function Item(props: any) {
    return (
        <>
            <img src={props.item.image} alt="Slider" />
            <h2 className="uc-slider-txt"><span>{props.item.name}</span> {props.item.description}</h2>
        </>
    )
}

const Login: FC<any> = (): ReactElement => {
    var md5 = require('md5');
    const [errorMessages, setErrorMessages] = useState({ name: "", message: '' });
    const login = (event: any) => {
        event.preventDefault();
        var { uname, pass } = document.forms[0];
        if (!(uname.value && uname.value !== '')) {
            uname.focus();
            setErrorMessages({ name: "pass", message: 'Username and Password cannot be empty.' });
            return false;
        }
        if (!(pass.value && pass.value !== '')) {
            pass.focus();
            setErrorMessages({ name: "pass", message: 'Password cannot be empty.' });
            return false;
        }
        AuthService.login(uname.value, md5(pass.value)).then((response: any) => {
            if (response.data) {
                var result = response.data;

                if (result.code === '1') {
                    let user = result.data
                    tokenService.set(result.access_token)
                    localStorage.setItem('refresh_token', result.refresh_token);
                    localStorage.setItem('user_token', user.id);
                    localStorage.setItem('user_fname', user.first_name);
                    localStorage.setItem('user_lname', user.last_name);
                    localStorage.setItem('user_email', user.email);
                    localStorage.setItem('user_image', user.profile_image);
                    window.location.reload();
                } else {
                    <Snackbar
                        open={true}
                        autoHideDuration={6000}

                        message="Profile Updated"

                    />
                    setErrorMessages({ name: "pass", message: result.message });
                }
            } else {
                setErrorMessages({ name: "pass", message: 'Something went wrong. Please try again Later.' });
            }
        }).catch((e: Error) => {
            console.log(e);
        });
    }

    const onSuccess = (res: any) => {
        let user_data = res['profileObj']
        let data = {
            "emailid": user_data['email'],
            "googleId": user_data['googleId'],
            "profiile_img": user_data['imageUrl'],
            "username": user_data['name'],
            "via_api": true
        }
        AuthService.googlelogin(data).then((response: any) => {
            if (response.data) {
                var result = response.data;

                if (result.code === '1') {
                    let user = result.data
                    tokenService.set(result.access_token)
                    // localStorage.setItem('refresh_token', result.refresh_token);
                    localStorage.setItem('user_token', user.id);
                    localStorage.setItem('user_fname', user.first_name);
                    localStorage.setItem('user_lname', user.last_name);
                    localStorage.setItem('user_email', user.email);
                    window.location.reload();
                } else {
                    <Snackbar
                        open={true}
                        autoHideDuration={6000}

                        message="Profile Updated"

                    />
                    setErrorMessages({ name: "pass", message: result.message });
                }
            } else {
                setErrorMessages({ name: "pass", message: 'Something went wrong. Please try again Later.' });
            }
        }).catch((e: Error) => {
            console.log(e);
        });
    };
    const onFailure = (err: any) => {
        console.log('failed:', err);
    };

    const renderErrorMessage = (name: any) =>

        name === errorMessages.name && (

            <div className="error">{errorMessages.message}</div>
        );

    interface State {
        amount: string;
        password: string;
        weight: string;
        weightRange: string;
        showPassword: boolean;
    }

    const [values, setValues] = React.useState<State>({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const [value, setValue] = React.useState('');

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const handleChange =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };



    return (
        <>
            <div className="uc-logo web-block">
                <a href="/">
                    <img src={process.env.PUBLIC_URL + 'assets/img/uletkz.png'} alt="ulektz-logo" />
                </a>
            </div>
            <div id="uc-container">
                <div className="uc-container-left">
                    <LoginSlider />
                </div>
                <div className="uc-container-right">
                    <div className="uc-cont-wrapper">
                        <p id="uc-newtoulektz" className="mobile-none">New to uLektz? <a id="uc-join-link" href="https://www.ulektz.com/start/register">Join now</a></p>
                        <div className="uc-logo mobile-none">
                            <a href="/">
                                <img src={process.env.PUBLIC_URL + 'assets/img/uletkz.png'} alt="ulektz-logo" />
                            </a>
                        </div>
                        <h3 className="uc-title">Login to your uLektz Account</h3>
                        <form onSubmit={login}>
                            <FormControl fullWidth className="loginFormControl">
                                <InputLabel size="small" htmlFor="outlined-adornment-username">Email</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-username"
                                    type={'text'}
                                    name="uname"
                                    value={value}
                                    onChange={handleChangeInput}
                                    autoComplete='off'
                                    size="small"
                                    label="Email"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <img src={process.env.PUBLIC_URL + 'assets/img/Email-icon.svg'} alt="ulektz-logo" />
                                        </InputAdornment>
                                    }
                                />

                                {renderErrorMessage("uname")}
                            </FormControl>
                            <FormControl fullWidth className="loginFormControl">
                                <InputLabel size="small" htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    name="pass"
                                    value={values.password}
                                    onChange={handleChange('password')}
                                    autoComplete='off'
                                    size="small"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {!values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <img src={process.env.PUBLIC_URL + 'assets/img/Password-icon.svg'} alt="ulektz-logo" />
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                                {renderErrorMessage("pass")}
                            </FormControl>
                            <a className="uc-forgot-pass" href="https://www.ulektz.com/start/forgotpassword">Forgot password?</a>
                            <Button type="submit" variant="contained" className="signInButton" fullWidth>Sign in</Button>
                            <h6 style={{ paddingTop: 0, visibility: "hidden" }}>OR</h6>

                            {/* <GoogleLogin
                             clientId="57282909951-h50drt6saje3pv7i44muvnrlsmslfs58.apps.googleusercontent.com"
                             buttonText="Signin with Google"
                             onSuccess={onSuccess}
                             onFailure={onFailure}
                             className="customGPlusSignIn"
                            /> */}


                            {/* <div id="googleButton" className="customGPlusSignIn">
                                <span className="icon"></span>
                                <span className="buttonText">Signin with Google</span>
                            </div> */}

                            <p id="uc-newtoulektz" className="web-block">New to uLektz? <a id="uc-join-link" href="https://www.ulektz.com/start/register">Join now</a></p>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Login
