import React from "react";
import "./radiobutton.css";

const RadioButton = (props:any) => {
    var subGroup = props.SubGroup
    if(subGroup){
        var options = subGroup.options;
    }
    
    return (
        <div className={'md-radio '+props.class}>
            <input name={props.name} id={props.id} onChange={props.changed} value={props.value} type={props.type} defaultChecked={props.isSelected}  />
            <label htmlFor={props.id}>{props.label}</label>
            { subGroup ?
                <div className="subGroup">
                {
                    options.map((item:any, index:any) => 
                    <div className="subGroupItem">
                        <input name={item.name} id={item.name+'_'+index} value={item.value} type={subGroup.type} onChange={props.groupChanged} defaultChecked={props.subPrivacy.includes(item.value)} />
                        <label htmlFor={item.name+'_'+index}>{item.label}</label> 
                    </div>
                    )
                }
                </div>
                :
                <></>
            }
        </div>
    );
}

export default RadioButton;
