import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Header from './Header/Header';
import Profile from '../context/Profile';
import ProfilePreference from '../context/ProfilePreference';
import Security from '../context/Security';
import Privacy from '../context/Privacy';

// import Wallet from '../context/Wallet';
import { useTranslation } from "react-i18next";

import React, { ReactElement, FC } from "react";

var tab_NAMES = [
  { tabImage:'account-profile-icon.svg', tabTitle: 'Profile',  },
  { tabImage:'account-preferences-icon.svg', tabTitle: 'Account Preference' },
  { tabImage:'account-security-icon.svg', tabTitle: 'Security' },
  { tabImage:'account-privacy-icon.svg', tabTitle: 'Privacy' },
  // { tabImage:'Wallet-icon.svg', tabTitle: 'Wallet' }
];
var tab_RESULT = [
  { tabKey: 'profile', tabPanel: <Profile></Profile> },
  { tabKey: 'profile_pref', tabPanel: <ProfilePreference></ProfilePreference> },
  { tabKey: 'security', tabPanel: <Security></Security> },
  { tabKey: 'privacy', tabPanel: <Privacy></Privacy> },
  // { tabKey: 'wallet', tabPanel: <Wallet></Wallet> },
];


const Layout: FC<any> = (): ReactElement => {

  const { t } = useTranslation();

  
  var verified = localStorage.getItem('mailConfirm');
  console.log("verified :"+ verified);
  return (
    <div>
      <Header></Header>
      {/* <p>{t('manish')}</p> */}
      <section>
        <div className="my-wrapper">
          <Tabs>
            <div className="ua-tab-section">
              <div className="ua-left-side">
                <h2>{t('account_overview')}</h2>
                <TabList>
                  {
                    tab_NAMES.map((tname) =>
                      <Tab key={tname.tabTitle} disabled={verified==='0'?true:false}>
                        <img src={process.env.PUBLIC_URL + 'assets/img/' + tname.tabImage } alt="" />
                        {tname.tabTitle}
                        <img src={process.env.PUBLIC_URL + 'assets/img/down-arrow.svg'} className="rht-arrow" alt="logo" />
                      </Tab>
                    )
                  }
                </TabList>
              </div>
              <div className="ua-right-side">
                {
                  tab_RESULT.map((tpanel) =>
                    <TabPanel key={tpanel.tabKey}>
                      {tpanel.tabPanel}
                    </TabPanel>
                  )
                }
              </div>
            </div>
          </Tabs>
        </div>
      </section>
    </div>
  )
}

export default Layout
