import React, { useEffect,useState } from "react";
import { styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Paper, FormControlLabel, Switch } from '@mui/material';
import CustomStyleMenu from '../../components/StyleMenu/StyleMenu';
import WalletConfigService from "../../services/wallet_config_service";
import CustomModal from '../../components/Modal/CustomModal';
import { useTranslation } from "react-i18next";
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import MuiAlert, { AlertProps,AlertColor } from '@mui/material/Alert'; 

//Table Design Starts
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

// function createData(
//     id: any,
//     status: any,
//     config: any,
//     code: any,
//     value: any,
// ) {
//     return { id, status, config, code, value };
// }
// Tabel Design Ends Here
// Switch Design Starts Here
const Android12Switch = styled(Switch)(() => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },

    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

// Switch Design Ends Here
// const style = {
//     position: 'absolute' as 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     p: 0,
//     borderRadius: 1
// };



const Activities = () => {

    // start Toast
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
      ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });
      const defaultToast = { isOpen: false, message: ""}
      const [toast, setToast] = useState(defaultToast);
      const [color] = useState<AlertColor>("success");
      const toastAutoHideTime = 1000;
      const openToast = (options:any) => {
        setToast({
          isOpen: true,
          message: options.message ? options.message : ""
        })
        setTimeout(function(){
          setToast({ isOpen: false, message: ""})
        }, toastAutoHideTime)
      }
      // End Toast

    const { t } = useTranslation();
    
    const [activities, setActivities] = React.useState([]);
    //console.log(activities);
    
   const getUser = () => {
        WalletConfigService.get()
          .then((response: any) => {
            let result = response.data
            setActivities(result)
            
          })
          .catch((e: Error) => {
            console.log(e);
          });
      };
      
    
    useEffect(() => {
        getUser()
        
      },[]);

    const [openmodal, setOpenModal] = React.useState(false);

    const [editSection, changeEditSection] = React.useState({ id:'', config:'', code:'', value:'', status:'' });

    function updateActivity(){
        WalletConfigService.update(editSection.id, editSection)
        .then((response: any) => {
          openToast({ message: response.data.message })
          getUser()
        })
        .catch((e: Error) => {
          console.log(e);
        });
    
        setOpenModal(false)
    }
    function updateActivityValue(value:any){
        editSection.value = value;
    }
    
    // const [value, setValue] = React.useState(0);
    // const [valueInner, setValueInner] = React.useState('');

    const handleChange = (newValue:any) => {
        if (newValue) {
            changeEditSection(newValue)
            // console.log(newValue)
            // if(newValue.activityName)
            //     setValueInner(newValue.activityName);
            // if(newValue.activityValue)
            //     setValue(newValue);
        }
        setOpenModal(true)
    };
    // const handleChangeInner = (newValueInner: number) => {
    //     setValueInner(newValueInner);
        
    // };

    //const handleOpenModal = () => setOpenModal(true);
    // const handleCloseModal = () => setOpenModal(false);
    const statusChange = (value:any,id:any) =>{
        WalletConfigService.updateStatus(id,value)
        .then((response: any) => {
            openToast({ message: response.data.message })
          changeEditSection(response.data)
        })
        .catch((e: Error) => {
          console.log(e);
        });
        
    }

    const commonOptions = [
        { 'icon': 'edit', 'label': 'Edit', 'click': handleChange }
    ];

    // const rows = [
    //     createData(
    //         1,
    //         <FormControlLabel className="activityLabel" control={<Android12Switch defaultChecked />} label="" />,
    //         'Points per like',
    //         100,
    //         <CustomStyleMenu items={commonOptions} />
    //     ),
    //     createData(2, <FormControlLabel className="activityLabel"
    //         control={<Android12Switch defaultChecked />}
    //         label=""
    //     />, 'Points per share', 50, <CustomStyleMenu items={commonOptions} />),
    //     createData(3, <FormControlLabel className="activityLabel"
    //         control={<Android12Switch defaultChecked />}
    //         label=""
    //     />, 'Profile likes', 30, <CustomStyleMenu items={commonOptions} />),
    //     createData(4, <FormControlLabel className="activityLabel"
    //         control={<Android12Switch />}
    //         label=""
    //     />, 'Profile connection', 300, <CustomStyleMenu items={commonOptions} />),
    //     createData(<Skeleton animation="wave" />, <FormControlLabel className="activityLabel"
    //         control={<Android12Switch />}
    //         label=""
    //     />, <Skeleton animation="wave" />, <Skeleton animation="wave" />, <CustomStyleMenu items={commonOptions} />),
    // ];

    return (
        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table" className="activityTabel">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{t("s_no")}</StyledTableCell>
                            <StyledTableCell>{t("status")}</StyledTableCell>
                            <StyledTableCell>{t("name_of_activity")}</StyledTableCell>
                            <StyledTableCell>{t("allotted_points")}</StyledTableCell>
                            <StyledTableCell>{t("activity_action")}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {/* {
                    rows.map((rows) => (
                        <StyledTableRow key={rows.id}>
                            <StyledTableCell component="th" scope="row">
                                {rows.id}
                            </StyledTableCell>
                            <StyledTableCell>{rows.status}</StyledTableCell>
                            <StyledTableCell>{rows.config}</StyledTableCell>
                            <StyledTableCell>{rows.code}</StyledTableCell>
                            <StyledTableCell>{rows.value}</StyledTableCell>
                        </StyledTableRow>
                    ))
                    } */}
                     {
                    activities.map((rows:any,index:any) => (
                        <StyledTableRow key={rows.id}>
                            <StyledTableCell component="th" scope="row">
                                {index+1}
                            </StyledTableCell>
                            <StyledTableCell>
                               <FormControlLabel className="activityLabel" control={<Android12Switch defaultChecked={rows.status==='1'} onChange={e => statusChange(e.target.checked,rows.id)}/>} label="" />
                            </StyledTableCell>
                            <StyledTableCell>{rows.config}</StyledTableCell>
                            <StyledTableCell>{rows.value}</StyledTableCell>
                            <StyledTableCell><CustomStyleMenu items={commonOptions} values={{ id:rows.id, config:rows.config, value:rows.value ,code:rows.code,status:rows.status}}  /></StyledTableCell>
                        </StyledTableRow>
                    ))
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            <CustomModal modalID="modal_one" isOpen={openmodal} changeOpen={setOpenModal} title={'Edit activity points'} saveButtonText={'Save'} saveButtonFunction={() => { updateActivity() }} cancelButtonText={'Cancel'} cancelButtonFunction={() => { setOpenModal(false) }}>
                <div className="activity-modal-content">
                    <div><span className="actLeft">Activity Name</span><span className="actRight">{editSection.config}</span></div>
                    <div><span className="actLeft">Set points</span><span className="actRight"><input type="text" className="uc-formControl" onChange={e => updateActivityValue(e.target.value)} defaultValue={editSection.value} /></span></div>
                </div>
            </CustomModal>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={toast.isOpen} >
                    <Alert  severity={color} sx={{ width: '100%' }}>
                    {toast.message}
                    </Alert>
                </Snackbar>
            </Stack>
        </div>
    )
}


export default Activities
