import authRequest from "../util/api_util";
import WalletConfig from "../types/WalletConfig";
const get = () => {
  return authRequest.get<WalletConfig>(`/wallets/wallet-config`);
};
const updateStatus = (id:any,data:any) => {
  return authRequest.put<any>(`/wallets/wallet-status/${id}`,data);
};
const update = (id: any, data: any) => {
  return authRequest.put<any>(`/wallets/wallet-config/${id}`, data);
};


const WalletConfigService = {
  get,
  update,
  updateStatus,
};
export default WalletConfigService;