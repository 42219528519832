import { FC , useState } from "react";

const Dropdown: FC<any> = ({ selected , setSelected , dropDownOptions }) => {
    const [isActive, setIsActive] = useState(false);
    const options = dropDownOptions;

    return (
        <div className="dropDown">
            <div className="dropDown-btn" onClick={(e) => setIsActive(!isActive)}>
                {selected}
                <span className="dropDown-icon"></span>
            </div>
            {isActive && (
                <>
                    <div className="dropDownOverlay" onClick={(e) => setIsActive(!isActive)}></div>
                    <div className="dropDown-content">
                        {options.map((option:any, key:any) => (
                            <div key={key} onClick={(e) => {
                                setSelected(option);
                                setIsActive(false);
                                }}
                                className="dropDown-item">
                                {option}
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}
export default Dropdown;