import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import '../../App.css';
import { useTranslation } from "react-i18next";

import React, { ReactElement, FC } from "react";
const AccordianNew: FC<any> = (props): ReactElement => {
    const { t } = useTranslation();
  return (
    <>
      <h2>{t('account_detail')}</h2>
      <Accordion allowZeroExpanded={true} preExpanded={['acc0']}>
            {
                props.children.map((item:any, index:any) =>
                    <AccordionItem uuid={'acc'+index}>
                        <AccordionItemHeading onClick={() => {props.onChange('acc'+index)}}>
                            <AccordionItemButton>
                                <h3>{props.title[index]}</h3>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {item}
                        </AccordionItemPanel>
                    </AccordionItem>
                )
             }
        </Accordion>
    </>
  )
}

export default AccordianNew
