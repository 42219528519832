import React, { ReactElement, FC, useState, useEffect } from "react";
import './pages.css';
import Accordion from '../components/Accordion/Accordion';
import { useForm } from "react-hook-form";
import DeviceDataService from "../services/device_service";
import UserDataService from "../services/user_service";
import { useTranslation } from "react-i18next";
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import tokenService from '../services/token_service';
import { useNavigate } from "react-router-dom";
import MuiAlert, { AlertProps,AlertColor } from '@mui/material/Alert';
import dayjs from 'dayjs';
type FormInputs = {
  old_password: string;
  password: string;
  confirm_password: string;
};
const Security: FC<any> = (): ReactElement => {
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const navigate = useNavigate();
  const defaultToast = { isOpen: false, message: ""}
  const [toast, setToast] = useState(defaultToast);
  const [timeDifference, setTimeDifference] = useState('');
  const [timestamp, setTimestamp] = useState(1693375834);
  const [color, setColor] = useState<AlertColor>("success");
  const toastAutoHideTime = 1000;
  const [error, setCustomError] = useState<any>({code:false,message:''});
  const openToast = (options:any) => {
    setToast({
      isOpen: true,
      message: options.message ? options.message : ""
    })
    setTimeout(function(){
      setToast({ isOpen: false, message: ""})
    }, toastAutoHideTime)
  }
  const {
    register,
    handleSubmit,
    reset,
    setError,
    getValues,
    formState: { errors }
  } = useForm<FormInputs>();


  const onSubmit = (data:any) => {
    if(data.password.length < 5 && data.confirm_password <5){
      setCustomError({code:true,message:'Password must be 5 characters or more'})
      return false
    }
    if(data.password === data.confirm_password){
      setCustomError({code:false,message:''});
      passwordchange.old_password = md5(data.old_password)
      passwordchange.password = data.password
      passwordchange.confirm_password = data.confirm_password
      updateUser()
    } else {
       setCustomError({code:true,message:'Password Mismatch'})
    }
    
  }

  // const onSubmit = (event: any) => {
  //   alert()
  //   //reset(); // reset after form submit
  // };

  const user_id=localStorage.getItem('user_token');

  const [passwordchange, setPassword] = useState<any>({});
  const [session, setSession] = useState<boolean>(false);
  const handleSession = () =>{
    setSession(!session);
  }
  const updateUser = () =>{
    
    if(Object.keys(passwordchange).length !== 0){
      if(passwordchange.password === passwordchange.confirm_password){
        setCustomError({code:false,message:''})
      UserDataService.changepassword(user_id, passwordchange)
        .then((response: any) => {
          setPassword({})
          if(response.data.message === 'Update Successful'){
            setColor("success")
            let element: HTMLElement = document.getElementsByClassName('accordion__button')[0] as HTMLElement;
            element.click();
            openToast({message:response.data.message})
            reset()
            if(session){
              DeviceDataService.allDeviceLogout()
                .then((response: any) => {
                  console.log(response.data)
                })
                .catch((e: Error) => {
                  console.log(e);
                });
            }
            localStorage.clear();
            tokenService.set('')
            navigate('/')
            window.location.reload();
          }else{
            setCustomError({code:true,message:response.data.message})
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });
      }else{
        setCustomError({code:true,message:'Password Mismatch'})
      }
    } else {
      setCustomError({code:true,message:'Please Enter Data'})
    }
  }
  const LogoutDevice = (data:any) => {
    DeviceDataService.deviceLogout(data)
      .then((response: any) => {
        getDevice();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  
  const [device, setdevice] = useState("");
  const getDevice = () => {
    DeviceDataService.get()
      .then((response: any) => {
        setdevice(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const resetfun= () =>{
    setCustomError({code:false,message:""})
    reset({
      old_password: "",
      password: "",
      confirm_password: '',
    })
  }
  useEffect(() => {
  DeviceDataService.get()
      .then((response: any) => {
        console.log(user_id);
        setdevice(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }, []);
  const result = Object.values(device);
  const DeviceDisplay = (props: any) => {
    return (
      <div className="accord-straight-div">
        <div className="device">
          <img src={process.env.PUBLIC_URL + 'assets/img/system_icon.svg'} alt="profile-img" />
          <div className="device-name">
            <span>{props.devicename}</span>
            <p >{
              (props.last_seen != 0)?
                  (Math.abs(dayjs().unix() - props.last_seen) < 60) ?
                  (`${Math.abs(dayjs().unix() - props.last_seen)} seconds ago`)
                : (Math.abs(dayjs().unix() - props.last_seen) < 3600) ?
                  (`${Math.floor(Math.abs(dayjs().unix() - props.last_seen) / 60)} minutes ago`)
                : (Math.abs(dayjs().unix() - props.last_seen) < 86400) ?
                  (`${Math.floor(Math.abs(dayjs().unix() - props.last_seen) / 3600)} hours ago`)
                :(Math.abs(dayjs().unix() - props.last_seen) < 172800) ?
                  (`${Math.floor(Math.abs(dayjs().unix() - props.last_seen) / 86400)} days ago`)
                : dayjs.unix(props.last_seen).format('MMMM D, YYYY')
              :
              <span className="status">{'Active'}</span>
              }</p>
          </div>
        </div>
        <p className="device-place"><img src={process.env.PUBLIC_URL + 'assets/img/location.svg'} alt="location" />
        {props.location?props.location:'Chennai, Tamilnadu'}</p>
         <div className="uc-lgout-btn">
              <a href="javascript:void(0)" onClick={()=> LogoutDevice(props.id)} className="btn-work cancel-btn login-remove-popup-link">Logout</a>
            </div> 
      </div>
    );
  }

    

  const { t } = useTranslation();
  //Change Password
  var md5 = require('md5');

  const handleInputChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
   if(name === 'old_password'){
      if(value.length > 0)
        setError( name , { type: "" }, { shouldFocus: true });
      else 
        setError( name , { type: "required" }, { shouldFocus: true });
    }
    if(name === 'password'){
      if(value.length > 0)
        setError( name , { type: "" }, { shouldFocus: true });
      else 
        setError( name , { type: "required" }, { shouldFocus: true });
    //   if(value.length >= 5)
    //     setError( name , { type: "" }, { shouldFocus: true });
    //   else 
    //     setError( name , { type: "minLength" }, { shouldFocus: true });
    }
    if(name === 'confirm_password'){
      if(value.length > 0)
        setError( name , { type: "" }, { shouldFocus: true });
      else 
        setError( name , { type: "required" }, { shouldFocus: true });
        const singleValue = getValues("password");
        
      let element: string = (document.getElementById('password') as HTMLInputElement).value;
      if(value.length === 0 || value === element)
        setCustomError({code:false,message:''});
      else
        setCustomError({code:true,message:'Password Mismatch'});
    }
  };
  const testFunc = ( data:any ) => {
    //alert(data);
    resetfun()
  }
  return (
    <>
      <div className="ua-common-wrap">
        <div className="ua-accordion-wrap">
          <div className="ua-account-details">
            <Accordion  onChange={testFunc} title={['Change Password', 'Login Activity']} >
              <div className='acc0' id="tab-3">
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

                  <div className="choice-sec">
                    {/* <div className="date-pass">
                      <p><span className="uc-last-change">{t('last_changed')}</span><span className="date-bulletin">22/02/2022</span></p>
                      <a className="uc-pass-for" href="start/forgotpassword">{t('forgot_password')}</a>
                    </div> */}
                    <div className="uc-acc-pass-up-div">
                      <label htmlFor="">{t('current_password')}</label>
                      <input type="password" placeholder="******" {...register("old_password",{required: true})} onChange={handleInputChange} name="old_password" autoComplete="new-password" onFocus={(e) => { e.target.placeholder = "******" }} />
                      {errors?.old_password?.type === "required" && <p className="error">Current Password Field Required</p>}
                      {error.code === true && error.message==='Current Password is Wrong'? <p className="error">{error.message}</p>:''}
                    </div>
                    <div className="uc-acc-pass-up-div">
                      <label htmlFor="">{t('new_password')}</label>
                      <input type="password" placeholder="******" {...register("password",{required: true,minLength: 5})} onChange={handleInputChange} autoComplete="nope" name="password" id="password" onFocus={(e) => { e.target.placeholder = "******" }} />
                      {errors?.password?.type === "required" && <p className="error">New password Field Required</p>}
                      {errors?.password?.type === "minLength" && (<p className="error">Password must be 5 characters or more</p>)}
                    </div>
                    <div className="uc-acc-pass-up-div">
                      <label htmlFor="">{t('re_new_password')}</label>
                      <input type="password" placeholder="******" {...register("confirm_password",{required: true})} onChange={handleInputChange} name="confirm_password"  id="confirm_password" onFocus={(e) => { e.target.placeholder = "******" }} />
                      {errors?.confirm_password?.type === "required" && <p className="error">Re-enter New password Field Required</p>}
                    </div>
                    
                    {error.code === true && error.message!== 'Current Password is Wrong'?<p className="error">{error.message}</p>:''}
                    {/* <div className="checkbox-alldevice">
                      <input
                        type="checkbox"
                        id="allDevices"
                        className="alldevice-checkbox"
                        checked={session}
                        onChange={handleSession}
                      />
                      <label htmlFor="allDevices">{t("Require all Devices to sign in with new Password")}</label>
                    </div> */}
                    <div className="button-part">
                      
                      <button type="button" className="btn-work cancel-btn" onClick={() => resetfun()}>{t('Reset')}</button>
                      <button type="submit" className="btn-work save-btn">{t('update')}</button>
                    </div>

                  </div>
                </form>
              </div>
              <div className='acc1 acc-toggle-content'>
                <p className="accordian-sub">{t('device_log')}</p>
                {
                  result.map((item: any, index) =>
                    <DeviceDisplay sl_no={index} devicename={item.device_name} id={item.id} location={item.location}
                     last_seen={item.last_seen} device={item.device_service}/>
                  )
                }
              </div>
            </Accordion>
          </div>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={toast.isOpen} >
          <Alert  severity={color} sx={{ width: '100%' }}>
            {toast.message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default Security;
