import {authRequest} from "../util/api_util";
import DeviceData from "../types/device";
const get = () => {
  return authRequest.get<DeviceData>(`/users/devices`);
};
const deviceLogout = (data: any) => {
  return authRequest.put<DeviceData>(`/users/devices`,data);
};
const allDeviceLogout = () => {
  return authRequest.patch<DeviceData>(`/users/devices`);
};
const DeviceService = {
  get,
  deviceLogout,
  allDeviceLogout
};
export default DeviceService;