import React from 'react'
import { Box, Backdrop, Modal, Fade } from '@mui/material';
import Button from '@mui/material/Button';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 0,
    borderRadius: 1
};

const CustomModal = (props: any) => {
    
   
    style.width = props.width ? props.width : 400;

    

    //const [openmodal, setOpenModal] = React.useState(false);

    const handleCloseModal = () => props.changeOpen(false);

    //setOpenModal(props.isOpen)

  return (
    <>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.isOpen}
            onClose={handleCloseModal}
            className="activity-modal-main"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.isOpen}>
                <Box sx={style}>
                    <div className="modal-container">
                        <div className="activity-edit-modal">
                            <div className="modal-header">
                                <h3>{props.title}</h3>
                            </div>
                            <div className="modal-body">
                                {/* <div className="activity-modal-content">
                                    <div><span className="actLeft">Activity Name</span><span className="actRight">Points per like</span></div>
                                    <div><span className="actLeft">Set points</span><span className="actRight"><input type="text" className="uc-formControl" value={100} /></span></div>
                                </div> */}
                                {props.children}
                            </div>
                            <div className="modal-footer">
                                <Button variant="outlined" color="primary" onClick={props.cancelButtonFunction ? () => {props.cancelButtonFunction();handleCloseModal()} : handleCloseModal} >
                                    {props.cancelButtonText}
                                </Button>
                                <Button variant="contained" color="primary" onClick={props.saveButtonFunction}>
                                    {props.saveButtonText}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    </>
  )
}

export default CustomModal
