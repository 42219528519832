// import React from 'react';
import React, { useState,useEffect } from "react";
import {
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Typography,
  Box
} from '@mui/material';
import { useTranslation } from "react-i18next";
import config from '../../config/config.json';
import dayjs, { Dayjs } from 'dayjs';
// import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CampaignConfigService from '../../services/campaign_service';

//Tabs Design Starts
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  other?: object;
}



/**Table Design Starts**/
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
/**Table Design end **/

/*Transcation table dat start*/
function createtranscationData(
  date: any,
  campaign: any,
  budgetalloted: any,
  budgetspent: any,
  cardscratched: any,
  cardwon: any,
) {
  return { date, campaign, budgetalloted, budgetspent, cardscratched, cardwon };
}

/*Transcation table dat End*/

/*Unscratch card table dat start*/
function createunscratchData(
  date: any,
  time: any,
  name: any,
  email: any,
  phonenumber: any,
  unscratchcard: any,
) {
  return { date, time, name, email, phonenumber, unscratchcard };
}

const unscratchrow = [
  createunscratchData("02/05/2022", 9, 'Jhon', 'email@gmail.com', 9876543210, 1),

];

/*Unscratch card table dat End*/

/*  Card scratched table dat start*/
function createcardscratchData(
  time: any,
  name: any,
  email: any,
  phonenumber: any,
) {
  return { time, name, email, phonenumber };
}

const cardscratchrow = [
  createcardscratchData("09:15", 'Jhon', 'email@gmail.com', 9876543210),
  
];

/*  Card scratched table dat End*/

/* Unscratch Card table dat start*/
function createcardwonData(
  time: any,
  name: any,
  email: any,
  phonenumber: any,
  cardwon: any
) {
  return { time, name, email, phonenumber, cardwon };
}

const uncardwonrow = [
  createcardwonData("09:50", 'Jhon', 'email@gmail.com', 9876543210, 10),
  
];

/* Unscratch Card table dat End*/



function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;
  const other: any = props.other ? props.other : {};

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${other.type ? other.type + '-' : ''}${index}`}
      aria-labelledby={`simple-tab-${other.type ? other.type + '-' : ''}${index}`}
      className={(other.class ? other.class : '') + ' customTab'}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number, type?: string) {
  return {
    id: `simple-tab-${type ? type + '-' : ''}${index}`,
    'aria-controls': `simple-tabpanel-${type ? type + '-' : ''}${index}`,
  };
}

const Transaction = () => {
  const [valueInner, setValueInner] = React.useState(0);
  const [value, setValue] = React.useState<Dayjs | null>(dayjs());
  const [enabled, setEnabled] = useState('main');
  const [ucash,setUcash] = React.useState<any>([{}])
  const [scratched,setScratched] = React.useState<any>([{}])
  const [unscratched,setUnScratched] = React.useState<any>([{}])
  const [cardwon,setCardWon] = React.useState<any>([{}])
  const [totScratched,setTotScratched] = React.useState<any>([{}])
  const [totalCardWon,setTotalCardWon] = React.useState<any>([{}])
  const [totaluCash,setTotaluCash] = React.useState<any>([{}])
  const [redeemedCash,setRedeemedCash] = React.useState<any>([{}])
  const [redeemed, setRedeemed] = React.useState<any>(0);
  const [scratch_details,setScratchDetails] = React.useState<any>([{}])
  const [ucoupon,setUcoupon] = React.useState<any>([{}])
  const [allCoupon,setAllCoupon] = React.useState<any>([{}])

  const { t } = useTranslation();
  const getValue = (value:any) =>{
    setValue(value);
    let data = dayjs(value).format('YYYY-MM-DD');
    CampaignConfigService.ScratchDetails()
        .then((response: any) => {
          setScratchDetails(response.data);
          setRedeemed(response.data[1].redeemed_cash);
        })
        .catch((e: Error) => {
          console.log(e);
        });

    CampaignConfigService.walletScratch(data)
      .then((response: any) => {
         setUcash(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });

    CampaignConfigService.CouponScratch(data)
      .then((response: any) => {
        setUcoupon(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
    
    CampaignConfigService.Coupons()
      .then((response: any) => {
        console.log(response.data.length)
        setAllCoupon(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
    });

    CampaignConfigService.unScratchedCard()
        .then((response: any) => {
            setUnScratched(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
        });

    CampaignConfigService.ScratchedCard(data)
        .then((response: any) => {
            setScratched(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
        });
  
    CampaignConfigService.CardWon(data)
        .then((response: any) => {
            setCardWon(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
        });
    
    CampaignConfigService.TotalCardWon()
        .then((response: any) => {
          setTotalCardWon(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
        });

    CampaignConfigService.TotalRedeemed()
        .then((response: any) => {
          setRedeemedCash(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
        });
    
    CampaignConfigService.TotalUcashWon()
        .then((response: any) => {
          
          setTotaluCash(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
        });

    CampaignConfigService.TotalScratched()
        .then((response: any) => {
          setTotScratched(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
        });
  }
  const getDateValue = (value:any) =>{
    setValue(value);
    let data = dayjs(value).format('YYYY-MM-DD');
    CampaignConfigService.CouponScratch(data)
      .then((response: any) => {
        console.log(response.data);
        setUcoupon(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
    });
  }
  useEffect(() => {
    const current = new Date();
    getValue(current)
  }, []);
  // const [value, setValue] = React.useState(0);
  
  //   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //     if (newValue === 0) {
  //         setValueInner(newValue);
  //     }
  //     setValue(newValue);
  // };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const changeTab = (pagename:any) => {
    setEnabled(pagename);
    setPage(0);
  }
  const handleChangeInner = (event: React.SyntheticEvent, newValueInner: number) => {
    setEnabled(newValueInner===0?'main':'');
    setValueInner(newValueInner);
  };

  return (
    <div>
      <div className="">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={valueInner} onChange={handleChangeInner} aria-label="basic tabs example" className="walletSubTabs">
            <Tab label={t('ucash_transcation')} {...a11yProps(0, 'inner')} />
            <Tab label={t('coupon_transcation')} {...a11yProps(1, 'inner')} />
          </Tabs>
        </Box> 
        {
          enabled === 'main' &&
          <div className="inside-tab">
            <TabPanel value={valueInner} index={0} other={{ type: 'inner', class: 'tabInnerPanel' }}>
              <div className="ucash-tranct-top">
              <div className="date-div-box">
                <p>{t("date")} : </p>
                <div className="total-unscrath date-picker">                 
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Custom input"
                      value={value}
                      onChange={(newValue) => {
                        getValue(newValue);
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <input ref={inputRef} {...inputProps} />
                          {InputProps?.endAdornment}
                        </Box>
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              
                <div className="total-unscrath cursor-point" onClick={() => changeTab('unscratched')}>
                  {t("total_unscratched_card")} : <button>{scratch_details[0].tot_unscratched_card}</button>
                </div>
                <div className="total-unscrath" onClick={() => changeTab('total_ucase_awarded')}>
                  {t("total_ucash_awarded")} : <button>₹ {scratch_details[0].tot_ucase_awarded}</button>
                </div>
                <div className="total-unscrath paytm" onClick={() => changeTab('Redeemed')}>
                  {t("redeem_ucash_to_paytm")} : <button>₹ {redeemed?redeemed:0}</button>
                </div>
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table" className="activityTabel transactionsTable">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>{t("date")}</StyledTableCell>
                      <StyledTableCell>{t("campaign")}</StyledTableCell>
                      <StyledTableCell>{t("budget_allowed")}</StyledTableCell>
                      <StyledTableCell>{t("budget_spent")}</StyledTableCell>
                      <StyledTableCell>{t("card_scratched")}</StyledTableCell>
                      <StyledTableCell>{t("card_won")}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                    ucash.length>0?
                    ucash.map((row:any) => (
                      (row.budget_spent !== '' || row.budget_spent !== null)?
                      <StyledTableRow>
                        <StyledTableCell>{row.date}</StyledTableCell>
                        <StyledTableCell>{row.name ?? 'NA'}</StyledTableCell>
                        <StyledTableCell>₹{row.budget_per_day ?? 'NA'}</StyledTableCell>
                        <StyledTableCell>₹{''+row.budget_spent ?? '0'}</StyledTableCell>
                        <StyledTableCell onClick={() => changeTab('scratched')}>
                          <button className="card-scratch">{row.scratched_card}</button>
                        </StyledTableCell>
                        <StyledTableCell onClick={() => changeTab('cardwon')}>
                          <button className="card-scratch-won">{row.card_won}</button>
                        </StyledTableCell>
                      </StyledTableRow>
                    :<></>
                    ))
                    :<TableRow>
                      <TableCell colSpan={6}>No Scrach Card found </TableCell>
                    </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </div>
        }
        {
        enabled !== 'couponUnScratched' &&
          <div className="inside-tab">
          <TabPanel value={valueInner} index={1} other={{ type: 'inner', class: 'tabInnerPanel' }}>
          <div className="ucash-tranct-top">
                <div className="date-div-box">
                  <p>{t("date")} : </p>
                  <div className="total-unscrath date-picker">                 
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Custom input"
                        value={value}
                        onChange={(newValue) => {
                          getDateValue(newValue);
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <input ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                
                  <div className="total-unscrath cursor-point" onClick={() => changeTab('couponUnScratched')}>
                    {t("coupon_transcation")} : <button>{allCoupon.length}</button>
                  </div>
                </div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table" className="activityTabel transactionsTable">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>{t("date")}</StyledTableCell>
                        <StyledTableCell>{t("time")}</StyledTableCell>
                        <StyledTableCell>{t("name")}</StyledTableCell>
                        <StyledTableCell>{t("email")}</StyledTableCell>
                        <StyledTableCell>{t("phone_number")}</StyledTableCell>
                        <StyledTableCell>{t("coupon_transcation")}</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                      ucoupon.length > 0 ?
                      ucoupon.map((row:any) => (
                        <StyledTableRow>
                        <StyledTableCell>{row.date}</StyledTableCell>
                        <StyledTableCell>{row.time}</StyledTableCell>
                        <StyledTableCell><a className="text-color" href={config.uLektz_Base_Path+"/in/"+row.profile_url}>{row.first_name}</a></StyledTableCell>
                        <StyledTableCell>{row.email}</StyledTableCell>
                        <StyledTableCell>{row.mobile_no}</StyledTableCell>
                        <StyledTableCell>{row.merchant_name}</StyledTableCell>
                      </StyledTableRow>
                      ))
                      :<TableRow>
                        <TableCell colSpan={6}>No Coupons Scrach Card </TableCell>
                      </TableRow>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
          </TabPanel>
          </div>
        }
      </div>
      {
        enabled === 'unscratched' &&
        <div className="unscratch-div unscratched-card-details">
          <div className="top-head" >
            <div className="top-head-left">
              <button onClick={() => changeTab('main')}>
                <img src={process.env.PUBLIC_URL + 'assets/img/back-arrow.svg'} alt="" />
              </button>{t("unscratched_cards")}
            </div>
            <div className="top-head-right">
            <div className="total-unscrath cursor-point" onClick={() => changeTab('unscratched')}>
                  {t("total_unscratched_card")} : <button>{scratch_details[0].tot_unscratched_card}</button>
                </div>
            </div>
          </div>
          <div className="unscrathch-table">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table" className="activityTabel Unscratched table-p-12">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{t("date")}</StyledTableCell>
                    <StyledTableCell>{t("time")}</StyledTableCell>
                    <StyledTableCell>{t("name")}</StyledTableCell>
                    <StyledTableCell>{t("email")}</StyledTableCell>
                    <StyledTableCell>{t("phone_number")}</StyledTableCell>
                    <StyledTableCell>{t("unscratched_card")}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unscratched
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row:any) => (
                    <StyledTableRow>
                      <StyledTableCell>{row.date}</StyledTableCell>
                      <StyledTableCell>{row.time}</StyledTableCell>
                      <StyledTableCell><a className="text-color" href={config.uLektz_Base_Path+"/in/"+row.profile_url}>{row.first_name}</a></StyledTableCell>
                      <StyledTableCell>{row.email}</StyledTableCell>
                      <StyledTableCell>{row.mobile_no}</StyledTableCell>
                      <StyledTableCell>{parseInt(row.no_of_card)}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={unscratched.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
          </div>
        </div>
      }
      {
        enabled === 'scratched' &&
        <div className="unscratch-div unscratched-card-details">
            <div className="top-head" >
              <div className="top-head-left">
                  <button onClick={() => changeTab('main')}>
                      <img src={process.env.PUBLIC_URL + 'assets/img/back-arrow.svg'} alt="" />
                  </button> {t("card_scratched")} - <span>{dayjs(value).format('DD/MM/YYYY')}</span>
              </div>  
              <div className="top-head-right">
              <div className="total-unscrath cursor-point" onClick={() => changeTab('total_card_scratched')}>
                    {t("total_card_scratched")} : <button>{scratch_details[0].tot_scratched_card}</button>
                  </div>
              </div>            
            </div>
            <div className="unscrathch-table">
              <TableContainer component={Paper}>        
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" className="activityTabel cardscratched table-p-12">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>{t("time")}</StyledTableCell>
                                <StyledTableCell>{t("name")}</StyledTableCell>
                                <StyledTableCell>{t("email")}</StyledTableCell>
                                <StyledTableCell>{t("phone_number")}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {scratched
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row:any) => (
                                <StyledTableRow>
                                    <StyledTableCell>{row.time}</StyledTableCell>
                                    <StyledTableCell>{row.first_name}</StyledTableCell>
                                    <StyledTableCell>{row.email}</StyledTableCell>
                                    <StyledTableCell>{row.mobile_no}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
              </TableContainer>   
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={scratched.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
          </div>
          </div>
          
      }
      {
        enabled === 'cardwon' &&
        <div className="unscratch-div unscratched-card-details">
            <div className="top-head" >
              <div className="top-head-left">
                  <button onClick={() => changeTab('main')}>
                      <img src={process.env.PUBLIC_URL + 'assets/img/back-arrow.svg'} alt="" />
                  </button> Card Won <span>- {dayjs(value).format('DD/MM/YYYY')}</span>
              </div>
              <div className="top-head-right">
                    <div className="total-unscrath" onClick={() => changeTab('total_ucase_awarded')}>
                      {t("total_ucash_awarded")} :<button>₹ {scratch_details[0].tot_ucase_awarded}</button>
                    </div>
                    <div className="total-unscrath" onClick={() => changeTab('total_card_won')}>
                      {t("total_cards_won")} :<button>{scratch_details[0].tol_card_won}</button>
                    </div>
              </div>              
            </div>
            <div className="unscrathch-table">
              <TableContainer component={Paper}>        
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" className="activityTabel cardscratched cardwon table-p-12">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>{t("time")}</StyledTableCell>
                                <StyledTableCell>{t("name")}</StyledTableCell>
                                <StyledTableCell>{t("email")}</StyledTableCell>
                                <StyledTableCell>{t("phone_number")}</StyledTableCell>
                                <StyledTableCell>{t("ucash_won")}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cardwon
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row:any) => (
                                <StyledTableRow>
                                    <StyledTableCell>{row.time}</StyledTableCell>
                                    <StyledTableCell><a className="text-color" href={config.uLektz_Base_Path+'/in/'+row.profile_url}>{row.first_name}</a>
                                    </StyledTableCell>
                                    <StyledTableCell>{row.email}</StyledTableCell>
                                    <StyledTableCell>{row.mobile_no}</StyledTableCell>
                                    <StyledTableCell>{row.total_amount}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
              </TableContainer>   
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={cardwon.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
          </div>
        </div>
      }

      {
        enabled === 'total_card_won' &&
        <div className="unscratch-div unscratched-card-details">
            <div className="top-head" >
              <div className="top-head-left">
                  <button onClick={() => changeTab('main')}>
                      <img src={process.env.PUBLIC_URL + 'assets/img/back-arrow.svg'} alt="" />
                  </button> Total Card Won 
              </div>
              <div className="top-head-right">
                  
                    <div className="total-unscrath" onClick={() => changeTab('total_ucase_awarded')}>
                      {t("total_ucash_awarded")} :<button>₹ {scratch_details[0].tot_ucase_awarded}</button>
                    </div>
                    <div className="total-unscrath" onClick={() => changeTab('total_card_won')}>
                      {t("total_cards_won")} : <button>{scratch_details[0].tol_card_won}</button>
                    </div>
              </div>              
            </div>
            <div className="unscrathch-table">
              <TableContainer component={Paper}>        
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" className="activityTabel cardscratched cardwon table-p-12">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>{t("date")}</StyledTableCell>
                                <StyledTableCell>{t("time")}</StyledTableCell>
                                <StyledTableCell>{t("name")}</StyledTableCell>
                                <StyledTableCell>{t("email")}</StyledTableCell>
                                <StyledTableCell>{t("phone_number")}</StyledTableCell>
                                <StyledTableCell>{t("ucash_won")}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {totalCardWon
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row:any) => (
                                <StyledTableRow>
                                    <StyledTableCell>{row.date}</StyledTableCell>
                                    <StyledTableCell>{row.time}</StyledTableCell>
                                    <StyledTableCell><a className="text-color" href={config.uLektz_Base_Path+'/in/'+row.profile_url}>{row.first_name}</a>
                                    </StyledTableCell>
                                    <StyledTableCell>{row.email}</StyledTableCell>
                                    <StyledTableCell>{row.mobile_no}</StyledTableCell>
                                    <StyledTableCell>{row.total_amount}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
              </TableContainer>   
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalCardWon.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
          </div>
        </div>
      }

      {
        enabled === 'total_card_scratched' &&
        <div className="unscratch-div unscratched-card-details">
            <div className="top-head" >
              <div className="top-head-left">
                  <button onClick={() => changeTab('main')}>
                      <img src={process.env.PUBLIC_URL + 'assets/img/back-arrow.svg'} alt="" />
                  </button> {t("total_card_scratched")} 
              </div>
              <div className="top-head-right">
                  <div className="total-unscrath" onClick={() => changeTab('total_card_won')}>
                      {t("total_cards_won")} : <button>{scratch_details[0].tol_card_won}</button>
                    </div>
                    <div className="total-unscrath" onClick={() => changeTab('total_ucase_awarded')} >
                      {t("total_ucash_awarded")} :<button>₹ {scratch_details[0].tot_ucase_awarded}</button>
                    </div>
              </div>              
            </div>
            <div className="unscrathch-table">
              <TableContainer component={Paper}>        
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" className="activityTabel cardscratched cardwon table-p-12">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>{t("date")}</StyledTableCell>
                                <StyledTableCell>{t("time")}</StyledTableCell>
                                <StyledTableCell>{t("name")}</StyledTableCell>
                                <StyledTableCell>{t("email")}</StyledTableCell>
                                <StyledTableCell>{t("phone_number")}</StyledTableCell>
                                <StyledTableCell>{t("ucash_won")}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {totScratched
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row:any) => (
                                <StyledTableRow>
                                    <StyledTableCell>{row.date}</StyledTableCell>
                                    <StyledTableCell>{row.time}</StyledTableCell>
                                    <StyledTableCell><a className="text-color" href={config.uLektz_Base_Path+'/in/'+row.profile_url}>{row.first_name}</a>
                                    </StyledTableCell>
                                    <StyledTableCell>{row.email}</StyledTableCell>
                                    <StyledTableCell>{row.mobile_no}</StyledTableCell>
                                    <StyledTableCell>{row.amount}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
              </TableContainer>   
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totScratched.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
          </div>
        </div>
      }

      {
        enabled === 'total_ucase_awarded' &&
        <div className="unscratch-div unscratched-card-details">
            <div className="top-head" >
              <div className="top-head-left">
                  <button onClick={() => changeTab('main')}>
                      <img src={process.env.PUBLIC_URL + 'assets/img/back-arrow.svg'} alt="" />
                  </button> {t("total_ucash_awarded")} 
              </div>
              <div className="top-head-right">
                  <div className="total-unscrath" onClick={() => changeTab('total_card_won')}>
                      {t("total_cards_won")} : <button>{scratch_details[0].tol_card_won}</button>
                    </div>
                    <div className="total-unscrath" onClick={() => changeTab('total_ucase_awarded')}>
                      {t("total_ucash_awarded")} : <button>₹ {scratch_details[0].tot_ucase_awarded}</button>
                    </div>
              </div>              
            </div>
            <div className="unscrathch-table">
              <TableContainer component={Paper}>        
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" className="activityTabel cardscratched cardwon table-p-12">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>{t("date")}</StyledTableCell>
                                <StyledTableCell>{t("time")}</StyledTableCell>
                                <StyledTableCell>{t("name")}</StyledTableCell>
                                <StyledTableCell>{t("email")}</StyledTableCell>
                                <StyledTableCell>{t("phone_number")}</StyledTableCell>
                                <StyledTableCell>{t("ucash_won")}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {totaluCash
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row:any) => (
                                <StyledTableRow>
                                    <StyledTableCell>{row.date}</StyledTableCell>
                                    <StyledTableCell>{row.time}</StyledTableCell>
                                    <StyledTableCell><a className="text-color" href={config.uLektz_Base_Path+'/in/'+row.profile_url}>{row.first_name}</a>
                                    </StyledTableCell>
                                    <StyledTableCell>{row.email}</StyledTableCell>
                                    <StyledTableCell>{row.mobile_no}</StyledTableCell>
                                    <StyledTableCell>{row.amount}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
              </TableContainer>   
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totaluCash.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
          </div>
        </div>
      }

      {
        enabled === 'Redeemed' &&
        <div className="unscratch-div unscratched-card-details">
            <div className="top-head" >
              <div className="top-head-left">
                  <button onClick={() => changeTab('main')}>
                      <img src={process.env.PUBLIC_URL + 'assets/img/back-arrow.svg'} alt="" />
                  </button> Redeemed uCash to Paytm
              </div>
              <div className="top-head-right">
                    <div className="total-unscrath" onClick={() => changeTab('total_ucase_awarded')}>
                      {t("total_ucash_awarded")} : <button>{scratch_details[0].tot_ucase_awarded}</button>
                    </div>
                    
                    <div className="total-unscrath" onClick={() => changeTab('Redeemed')}>
                      {t("redeem_ucash_to_paytm")} :  <button>₹ {redeemed?redeemed:0}</button>
                    </div>
                   
              </div>              
            </div>
            <div className="unscrathch-table">
              <TableContainer component={Paper}>        
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" className="activityTabel cardscratched cardwon table-p-12">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>{t("date")}</StyledTableCell>
                                <StyledTableCell>{t("time")}</StyledTableCell>
                                <StyledTableCell>{t("name")}</StyledTableCell>
                                <StyledTableCell>{t("email")}</StyledTableCell>
                                <StyledTableCell>{t("phone_number")}</StyledTableCell>
                                <StyledTableCell>{t("redeemed_cash")}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {redeemedCash
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row:any) => (
                                <StyledTableRow>
                                    <StyledTableCell>{row.date}</StyledTableCell>
                                    <StyledTableCell>{row.time}</StyledTableCell>
                                    <StyledTableCell><a className="text-color" href={config.uLektz_Base_Path+'/in/'+row.profile_url}>{row.first_name}</a>
                                    </StyledTableCell>
                                    <StyledTableCell>{row.email}</StyledTableCell>
                                    <StyledTableCell>{row.transfer_to}</StyledTableCell>
                                    <StyledTableCell>{row.amount}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
              </TableContainer>   
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={redeemedCash.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
          </div>
        </div>
      }
      { 
        enabled === 'couponUnScratched' &&
          <div className="unscratch-div unscratched-card-details">
            <div className="top-head" >
              <div className="top-head-left">
                  <button onClick={() => changeTab('main')}>
                      <img src={process.env.PUBLIC_URL + 'assets/img/back-arrow.svg'} alt="" />
                  </button> 
              </div>
              <div className="top-head-right">
                <div className="total-unscrath cursor-point" onClick={() => changeTab('couponUnScratched')}>
                  {t("coupon_transcation")} : <button>{allCoupon.length}</button>
                </div>
              </div>              
            </div>
            <div className="unscrathch-table">
              <TableContainer component={Paper}>        
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" className="activityTabel cardscratched cardwon table-p-12">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>{t("date")}</StyledTableCell>
                                <StyledTableCell>{t("time")}</StyledTableCell>
                                <StyledTableCell>{t("name")}</StyledTableCell>
                                <StyledTableCell>{t("email")}</StyledTableCell>
                                <StyledTableCell>{t("phone_number")}</StyledTableCell>
                                <StyledTableCell>{t("coupon_transcation")}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                            allCoupon.length > 0 ?
                            allCoupon
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row:any) => (
                                <StyledTableRow>
                                  <StyledTableCell>{row.date}</StyledTableCell>
                                  <StyledTableCell>{row.time}</StyledTableCell>
                                  <StyledTableCell><a className="text-color" href={config.uLektz_Base_Path+"/in/"+row.profile_url}>{row.first_name}</a></StyledTableCell>
                                  <StyledTableCell>{row.email}</StyledTableCell>
                                  <StyledTableCell>{row.mobile_no}</StyledTableCell>
                                  <StyledTableCell>{row.total_coupon}</StyledTableCell>
                                </StyledTableRow>
                            ))
                            :<TableRow>
                              <TableCell colSpan={6}>No Coupons Scrach Card </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
              </TableContainer>   
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={allCoupon.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
          </div>
        </div>
      }

    </div>
  )
}

export default Transaction
